import * as React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { Toast } from "../../../helpers/Toast";
import { userRequest } from "../../../api/UserRequest";

interface Props {
  toDeleteId: string;
  onDeleteSuccess: CallbackFunction;
  onResetToDeleteId: CallbackFunction;
}

export default function ConfirmDeleteUserDialog({
  toDeleteId,
  onDeleteSuccess,
  onResetToDeleteId,
}: Props) {
  const openDeleteConfirmation = Boolean(toDeleteId);

  return (
    <Dialog open={openDeleteConfirmation}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DeleteIcon sx={{ fontSize: "75px" }} />
        <DialogTitle id="alert-dialog-title">
          Sunteți sigur că doriți să ștergeți acest utilizator?
        </DialogTitle>
        <DialogActions
          sx={{
            minWidth: 520,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="error"
            onClick={() => {
              userRequest
                .delete(toDeleteId)
                .then(() => {
                  onDeleteSuccess();
                  Toast.showSuccess("Utilizator șters cu succes.");
                })
                .catch((e) => {
                  console.error(e);
                  Toast.showError(
                    "A apărut o eroare. Vă rugăm încercați mai târziu.",
                  );
                })
                .finally(onResetToDeleteId);
            }}
            autoFocus
          >
            Șterge
          </Button>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={onResetToDeleteId}
          >
            Renunță
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
