import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { roRO } from "@mui/x-data-grid";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#c00909",
      },
      secondary: {
        main: "#19857b",
      },
      error: {
        main: red.A400,
      },
    },
  },
  roRO,
);

export default theme;
