import { User, UserRole } from "../../../api/types/user";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { userRequest } from "../../../api/UserRequest";
import { Toast } from "../../../helpers/Toast";
import { useAppSelector } from "../../../hooks/redux";
import { authUserSelector } from "../../../redux/slices/selectors";

interface Props {
  open: boolean;
  onClose: CallbackFunction;
  onRequestSuccess: CallbackFunction;
  user?: User;
}

export default function CreateUpdateUserDialog({
  open,
  onClose,
  user,
  onRequestSuccess,
}: Props) {
  const authUser = useAppSelector(authUserSelector);
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  const validationSchema = yup.object({
    username: yup.string().required("Numele de utilizator e obligatoriu"),
    firstName: yup.string().required("Prenumele e obligatoriu"),
    lastName: yup.string().required("Numele e obligatoriu"),
    role: yup
      .string()
      .oneOf(Object.values(UserRole))
      .required("Rolul e obligatoriu"),
    password: yup
      .string()
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
        "Parola trebuie să conțină minim 8 caractere, o majusculă, o literă mică, un caracter special",
      )
      [user ? "optional" : "required"](
        user ? undefined : "Parola e obligatorie",
      ),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      role: UserRole.Manager,
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setRequestIsLoading(true);
      if (user) {
        userRequest
          .update(user.id, {
            ...values,
            password: values.password ? values.password : undefined,
          })
          .then(() => {
            Toast.showSuccess("Utilizator modificat cu succes.");
            onRequestSuccess();
            closeDialog();
          })
          .catch((e) => {
            console.error(e);
            Toast.showError(
              "A apărut o eroare. Vă rugăm încercați mai târziu.",
            );
          })
          .finally(() => {
            setRequestIsLoading(false);
            actions.setSubmitting(false);
          });
      } else {
        userRequest
          .create(values)
          .then(() => {
            Toast.showSuccess("Utilizator adăugat cu succes.");
            onRequestSuccess();
            closeDialog();
          })
          .catch((e) => {
            console.error(e);
            Toast.showError(
              "A apărut o eroare. Vă rugăm încercați mai târziu.",
            );
          })
          .finally(() => {
            setRequestIsLoading(false);
            actions.setSubmitting(false);
          });
      }
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    formik.setValues({
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      password: "",
    });
  }, [user]);

  const closeDialog = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <Box sx={{ padding: 2 }}>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{user ? "Modifică" : "Adaugă"} utilizator</Grid>
            <Grid item>
              <IconButton onClick={closeDialog} disabled={requestIsLoading}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              variant="standard"
              fullWidth
              id="username"
              name="username"
              label="Nume utilizator"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              disabled={requestIsLoading}
            />
            <TextField
              variant="standard"
              fullWidth
              id="firstName"
              name="firstName"
              label="Prenume"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              disabled={requestIsLoading}
            />
            <TextField
              variant="standard"
              fullWidth
              id="lastName"
              name="lastName"
              label="Nume"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              sx={{ mb: 2 }}
              disabled={requestIsLoading}
            />
            <Select
              variant="standard"
              labelId="role-label"
              id="role"
              value={formik.values.role}
              label="Rol"
              onChange={(e) => formik.setFieldValue("role", e.target.value)}
              fullWidth
              disabled={requestIsLoading || user?.id === authUser.id}
            >
              <MenuItem value={UserRole.Admin}>Admin</MenuItem>
              <MenuItem value={UserRole.Manager}>Manager</MenuItem>
            </Select>
            <TextField
              variant="standard"
              fullWidth
              id="password"
              name="password"
              label="Parolă"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={requestIsLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={requestIsLoading}
            >
              Salvează
              {requestIsLoading && (
                <CircularProgress sx={{ ml: 1 }} size={16} />
              )}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
