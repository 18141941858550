import { authRequest } from "./AuthRequest";
import AbstractRequest from "./AbstractRequest";
import { ApiMethod } from "./constants";
import { FileUploadResponseBody } from "./types/file";

export default class FileRequest extends AbstractRequest {
  get apiResource() {
    return "file";
  }

  get routes(): Record<string, string> {
    return {
      UPLOAD: `${this.apiRoute}/upload`,
      DOWNLOAD: `${this.apiRoute}/download/`,
    };
  }

  async upload(
    file: File,
    onUploadProgress?: AnyFunction,
  ): Promise<FileUploadResponseBody> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.request(
      ApiMethod.POST,
      this.routes.UPLOAD,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authRequest.token}`,
        },
        onUploadProgress,
      },
    );

    return response?.data;
  }

  getDownloadLink(fileId: string) {
    return this.routes.DOWNLOAD + fileId;
  }
}

export const fileRequest = new FileRequest();
