import AbstractRequest from "./AbstractRequest";
import { ApiMethod } from "./constants";
import { Supplier } from "./types/supplier";

export class SupplierRequest extends AbstractRequest {
  get apiResource(): string {
    return "supplier";
  }

  get routes(): Record<string, string> {
    return {
      FIND_ALL: `${this.apiRoute}`,
    };
  }

  async findAll(
    limit: number,
    offset: number,
    searchText?: string,
  ): Promise<{ results: Supplier[]; total: number }> {
    const queryParams = [
      { key: "limit", value: `${limit}` },
      { key: "offset", value: `${offset}` },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }
    const result = await this.request(
      ApiMethod.GET,
      this.routes.FIND_ALL + this.buildQueryParams(queryParams),
    );
    return result.data;
  }
}

export const supplierRequest = new SupplierRequest();
