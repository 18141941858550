import { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import AbstractRequest from "./AbstractRequest";
import AuthClientStore from "../client-store/AuthClientStore";
import { ApiMethod, AUTH_ME_SUBROUTE } from "./constants";

export interface LoginData {
  username: string;
  password: string;
}

export class AuthRequest extends AbstractRequest {
  private readonly authClientStoreService: typeof AuthClientStore;
  private requestInterceptorId: number | null;

  constructor(
    authClientStoreService: typeof AuthClientStore = AuthClientStore,
  ) {
    super();
    this.authClientStoreService = authClientStoreService;
    this._token = AuthClientStore.getToken();
    this.requestInterceptorId = null;
  }

  private _token: string | null;

  get token(): string | null {
    return this._token;
  }

  set token(token: string | null) {
    if (token) {
      this.authClientStoreService.setToken(token);
    } else {
      this.authClientStoreService.removeToken();
    }

    this._token = token;
  }

  get apiResource(): string {
    return "auth";
  }

  get routes(): Record<string, string> {
    return {
      LOGIN: `${this.apiRoute}/login`,
      ME: `${this.apiUrl}${AUTH_ME_SUBROUTE}`,
    };
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }
    this.requestInterceptorId = this.addRequestInterceptor(
      (config: AxiosRequestConfig) => {
        if (config?.headers) {
          config.headers.Authorization = `Bearer ${this.token}`;
        }

        return config;
      },
    );
  }

  unsetAuthorizationHeader() {
    if (typeof this.requestInterceptorId === "number") {
      this.removeRequestInterceptor(this.requestInterceptorId);
    }
    this.requestInterceptorId = null;
  }

  async login(data: LoginData) {
    const response = await this.request(
      ApiMethod.POST,
      this.routes.LOGIN,
      data,
    );
    this.token = response.data.access_token;
    this.setAuthorizationHeader();

    const decodedJwt = this.getDecodedJwt();
    return { user: { id: decodedJwt.sub, username: decodedJwt.username } };
  }

  logout(): null {
    this.token = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me() {
    this.setAuthorizationHeader();
    try {
      const result = await this.request(
        ApiMethod.GET,
        this.routes.ME!,
        undefined,
        { headers: { "Cache-Control": "no-store" } },
      );

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }

  getDecodedJwt(): { username: string; sub: string } {
    return jwtDecode(this.token ?? "");
  }
}

export const authRequest = new AuthRequest();
