import * as React from "react";
import { useEffect, useState } from "react";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { SupplierOrderStatus } from "../../../api/types/supplier-order";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  convertApiOrderToPdfOrder,
  downloadSupplierOrderPdf,
  printSupplierOrderPdf,
  SenderType,
  sendSupplierOrderPdf,
  SupplyOrderPdf,
} from "../../../helpers/pdf-renderer";
import ViewDocumentDialog from "../dialogs/ViewDocumentDialog";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Toast } from "../../../helpers/Toast";
import QuickSearchToolbar from "../../common/QuickSearchToolbar";
import eventEmitter, { EVENTS } from "../../../eventEmitter";
import { fileRequest } from "../../../api/FileRequest";

interface OrderedRow {
  id: number;
  supplierName: string;
  products: string;
  generationDate: Date;
  orderedDate: Date;
  supplyOrderPdf: SupplyOrderPdf;
  fileId: string;
}

export default function OrderedDocumentsDataGrid() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<OrderedRow[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [supplyOrderPdf, setSupplyOrderPdf] = useState<SupplyOrderPdf | null>(
    null,
  );

  const [searchText, setSearchText] = useState("");

  const fetchData = () => {
    setPageIsLoading(true);
    supplierOrderRequest
      .findAll(
        pageSize,
        page * pageSize,
        SupplierOrderStatus.Ordered,
        searchText,
      )
      .then((data) => {
        setRows(
          data.results.map((order) => ({
            id: order.id,
            supplierName: order.supplier.name,
            products: order.rows.map((row) => row.productId).join(", "),
            generationDate: new Date(order.createdAt),
            orderedDate: new Date(order.lastStatusDate!),
            supplyOrderPdf: convertApiOrderToPdfOrder(order),
            fileId: order.fileId as string,
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError(
          "A apărut o eroare la încărcarea documentelor comandate!",
        );
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchText]);

  useEffect(() => {
    eventEmitter.on(
      EVENTS.ACCEPTED_DOCUMENTS.REFETCH_ORDERED_DOCUMENTS,
      fetchData,
    );

    return () => {
      eventEmitter.off(
        EVENTS.ACCEPTED_DOCUMENTS.REFETCH_ORDERED_DOCUMENTS,
        fetchData,
      );
    };
  }, []);

  const columns: GridColDef<OrderedRow>[] = [
    {
      field: "id",
      headerName: "Cod fișă",
      width: 75,
      sortable: false,
    },
    {
      field: "supplierName",
      headerName: "Furnizor",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "products",
      headerName: "Produse",
      width: 150,
      flex: 2,
      sortable: false,
    },
    {
      field: "generationDate",
      headerName: "Dată generare",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "orderedDate",
      headerName: "Dată comandă",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "action",
      headerName: "Acțiune",
      minWidth: 210,
      maxWidth: 210,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              onClick={() => setSupplyOrderPdf(row.supplyOrderPdf)}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              size="small"
              color="warning"
              onClick={() => {
                sendSupplierOrderPdf(
                  SenderType.Email,
                  row.supplyOrderPdf,
                  row.fileId,
                );
              }}
            >
              <MailIcon />
            </IconButton>
            <IconButton
              size="small"
              color="success"
              onClick={() => {
                sendSupplierOrderPdf(
                  SenderType.Whatsapp,
                  row.supplyOrderPdf,
                  row.fileId,
                );
              }}
            >
              <WhatsAppIcon />
            </IconButton>
            <IconButton
              size="small"
              color="info"
              onClick={() => downloadSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              size="small"
              color="success"
              onClick={() => printSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                await navigator.clipboard.writeText(
                  fileRequest.getDownloadLink(row.fileId),
                );
                Toast.showSuccess(
                  "Link de descărcare document copiat in clipboard.",
                );
              }}
              color="primary"
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-cell:focus-within": {
            outline: "none ",
          },
        }}
        localeText={{
          noRowsLabel: "Nu există documente comandate",
          toolbarQuickFilterPlaceholder: "Căutare...",
        }}
        slotProps={{ pagination: { labelRowsPerPage: "Rânduri pe pagină:" } }}
        disableColumnMenu
        rowCount={rowCount}
        rows={rows}
        columns={columns}
        paginationMode="server"
        pagination
        pageSizeOptions={[5, 25, 50]}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        loading={pageIsLoading}
        disableRowSelectionOnClick
        slots={{ toolbar: QuickSearchToolbar }}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => {
          setSearchText(filterModel.quickFilterValues?.join(" ") || "");
        }}
      />
      <ViewDocumentDialog
        supplyOrder={supplyOrderPdf}
        onResetSupplyOrder={() => setSupplyOrderPdf(null)}
      />
    </>
  );
}
