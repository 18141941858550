import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { PATH } from "../router/path.helper";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/auth";
import { Logout } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { isAdminSelector } from "../redux/slices/selectors";

export const MainListItems = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(isAdminSelector);

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate(PATH.DASHBOARD)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(PATH.NECESSARY_GOODS)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Necesar marfă" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(PATH.UNSOLD_GOODS)}>
        <ListItemIcon>
          <ProductionQuantityLimitsIcon />
        </ListItemIcon>
        <ListItemText primary="Marfă nevândută" />
      </ListItemButton>
      {isAdmin && (
        <ListItemButton onClick={() => navigate(PATH.PENDING_DOCUMENTS)}>
          <ListItemIcon>
            <AssignmentLateIcon />
          </ListItemIcon>
          <ListItemText primary="Doc de aprobat" />
        </ListItemButton>
      )}
      <ListItemButton onClick={() => navigate(PATH.DOCUMENTS)}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText
          primary={isAdmin ? "Doc aprobate/refuzate" : "Documente"}
        />
      </ListItemButton>
      {isAdmin && (
        <ListItemButton onClick={() => navigate(PATH.USERS)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Utilizatori" />
        </ListItemButton>
      )}
      <ListItemButton onClick={() => dispatch(logout())}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Deconectare" />
      </ListItemButton>
    </React.Fragment>
  );
};
