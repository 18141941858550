import Layout from "../../layout/Layout";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import RejectedDocumentsDataGrid from "../../components/documents/data-grids/RejectedDocumentsDataGrid";
import PendingDocumentsDataGrid from "../../components/documents/data-grids/PendingDocumentsDataGrid";
import OrderedDocumentsDataGrid from "../../components/documents/data-grids/OrderedDocumentsDataGrid";
import AcceptedDocumentsDataGrid from "../../components/documents/data-grids/AcceptedDocumentsDataGrid";

export default function DocumentsPage() {
  return (
    <Layout title="Documente">
      <Grid mb={1}>
        <Typography component="h2" variant="h4" color="primary">
          Documente în asteptare
        </Typography>
        <Box sx={{ height: 350, width: "100%" }}>
          <PendingDocumentsDataGrid />
        </Box>
      </Grid>
      <Grid mb={1}>
        <Typography component="h2" variant="h4" color="primary">
          Documente aprobate
        </Typography>
        <Box sx={{ height: 350, width: "100%" }}>
          <AcceptedDocumentsDataGrid />
        </Box>
      </Grid>
      <Grid mb={1}>
        <Typography component="h2" variant="h4" color="primary">
          Documente refuzate
        </Typography>
        <Box sx={{ height: 350, width: "100%" }}>
          <RejectedDocumentsDataGrid />
        </Box>
      </Grid>
      <Grid>
        <Typography component="h2" variant="h4" color="primary">
          Documente comandate
        </Typography>
        <Box sx={{ height: 350, width: "100%" }}>
          <OrderedDocumentsDataGrid />
        </Box>
      </Grid>
    </Layout>
  );
}
