import { Supplier } from "./supplier";

export enum ProductStatus {
  Initial = "initial",
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Ordered = "ordered",
}

export interface Product {
  code: string;
  name: string;
  unitMeasurement: string;
  stock: string;
  minStock: number;
  previousStock?: number;
  stockDroppedAt?: Date;
  status: ProductStatus;
  supplierId?: number;
  supplier?: Supplier;
  updatedAt: string;
}
