import * as React from "react";
import { useEffect, useState } from "react";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { SupplierOrderStatus } from "../../../api/types/supplier-order";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import {
  convertApiOrderToPdfOrder,
  downloadSupplierOrderPdf,
  printSupplierOrderPdf,
  SenderType,
  SupplyOrderPdf,
} from "../../../helpers/pdf-renderer";
import ViewDocumentDialog from "../dialogs/ViewDocumentDialog";
import ConfirmOrderDocumentDialog from "../dialogs/ConfirmOrderDocumentDialog";
import { useAppSelector } from "../../../hooks/redux";
import { isAdminSelector } from "../../../redux/slices/selectors";
import { Toast } from "../../../helpers/Toast";
import QuickSearchToolbar from "../../common/QuickSearchToolbar";
import eventEmitter, { EVENTS } from "../../../eventEmitter";

interface AcceptedRow {
  id: number;
  supplierName: string;
  products: string;
  generationDate: Date;
  acceptedDate: Date;
  supplyOrderPdf: SupplyOrderPdf;
}

export default function AcceptedDocumentsDataGrid() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<AcceptedRow[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [supplyOrderPdf, setSupplyOrderPdf] = useState<SupplyOrderPdf | null>(
    null,
  );
  const [toOrderSupplyOrderPdf, setToOrderSupplyOrderPdf] =
    useState<SupplyOrderPdf | null>(null);
  const [toOrderSender, setToOrderSender] = useState(SenderType.Email);
  const isAdmin = useAppSelector(isAdminSelector);

  const [searchText, setSearchText] = useState("");

  const fetchData = () => {
    setPageIsLoading(true);
    supplierOrderRequest
      .findAll(
        pageSize,
        page * pageSize,
        SupplierOrderStatus.Accepted,
        searchText,
      )
      .then((data) => {
        setRows(
          data.results.map((order) => ({
            id: order.id,
            supplierName: order.supplier.name,
            products: order.rows.map((row) => row.productId).join(", "),
            generationDate: new Date(order.createdAt),
            acceptedDate: new Date(order.lastStatusDate!),
            supplyOrderPdf: convertApiOrderToPdfOrder(order),
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError(
          "A apărut o eroare la încărcarea documentelor aprobate!",
        );
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchText]);

  const columns: GridColDef<AcceptedRow>[] = [
    {
      field: "id",
      headerName: "Cod fișă",
      width: 75,
      sortable: false,
    },
    {
      field: "supplierName",
      headerName: "Furnizor",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "products",
      headerName: "Produse",
      width: 150,
      flex: 2,
      sortable: false,
    },
    {
      field: "generationDate",
      headerName: "Dată generare",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "acceptedDate",
      headerName: "Dată aprobare",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "action",
      headerName: "Acțiune",
      minWidth: isAdmin ? 120 : 180,
      maxWidth: isAdmin ? 120 : 180,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              onClick={() => setSupplyOrderPdf(row.supplyOrderPdf)}
            >
              <VisibilityIcon />
            </IconButton>
            {!isAdmin && (
              <>
                <IconButton
                  size="small"
                  color="warning"
                  onClick={() => {
                    setToOrderSender(SenderType.Email);
                    setToOrderSupplyOrderPdf(row.supplyOrderPdf);
                  }}
                >
                  <MailIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="success"
                  onClick={() => {
                    setToOrderSender(SenderType.Whatsapp);
                    setToOrderSupplyOrderPdf(row.supplyOrderPdf);
                  }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </>
            )}
            <IconButton
              size="small"
              color="info"
              onClick={() => downloadSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              size="small"
              color="success"
              onClick={() => printSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <PrintIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-cell:focus-within": {
            outline: "none ",
          },
        }}
        localeText={{
          noRowsLabel: "Nu există documente aprobate",
          toolbarQuickFilterPlaceholder: "Căutare...",
        }}
        slotProps={{ pagination: { labelRowsPerPage: "Rânduri pe pagină:" } }}
        disableColumnMenu
        rowCount={rowCount}
        rows={rows}
        columns={columns}
        paginationMode="server"
        pagination
        pageSizeOptions={[5, 25, 50]}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        loading={pageIsLoading}
        disableRowSelectionOnClick
        slots={{ toolbar: QuickSearchToolbar }}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => {
          setSearchText(filterModel.quickFilterValues?.join(" ") || "");
        }}
      />
      <ViewDocumentDialog
        supplyOrder={supplyOrderPdf}
        onResetSupplyOrder={() => setSupplyOrderPdf(null)}
      />
      <ConfirmOrderDocumentDialog
        toOrderSupplyOrder={toOrderSupplyOrderPdf}
        senderType={toOrderSender}
        onOrderSuccess={() => {
          fetchData();
          eventEmitter.emit(
            EVENTS.ACCEPTED_DOCUMENTS.REFETCH_ORDERED_DOCUMENTS,
          );
        }}
        onResetToOrderId={() => setToOrderSupplyOrderPdf(null)}
      />
    </>
  );
}
