import { Navigate } from "react-router-dom";
import * as React from "react";
import { useSelector } from "react-redux";
import { PATH } from "../router/path.helper";
import { isAuthenticatedSelector } from "../redux/slices/selectors";

export function RequireAuth({ children }: { children: any }) {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return children;
}
