import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import Layout from "../../layout/Layout";
import { productRequest } from "../../api/ProductRequest";
import { Toast } from "../../helpers/Toast";
import { ProductStatus } from "../../api/types/product";
import { darken, Grid, lighten, Tooltip } from "@mui/material";
import { useGetQuickSearchToolbar } from "../../components/common/QuickSearchToolbar";
import NecessaryGoodsPageFiltersDialog, {
  FiltersDto,
} from "../../components/documents/dialogs/NecessaryGoodsPageFiltersDialog";
import { getProductStatusLocale } from "../../helpers/product";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .product-status-${ProductStatus.Pending}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Rejected}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Accepted}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Ordered}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
})) as typeof DataGrid;

interface Row {
  id: string;
  name: string;
  unitMeasurement: string;
  stock: string;
  minStock: number;
  status: string;
  stockDroppedAt: Date | null;
}

export default function UnsoldGoodsPage() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<Row[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [filtersDto, setFiltersDto] = useState<FiltersDto>({});

  const [daysInput, setDaysInput] = useState<string | number>(60);
  const [days, setDays] = useState(60);

  const toolbar = useGetQuickSearchToolbar({
    activeFiltersCount: Object.keys(filtersDto).length,
    onFilterClick: () => setOpenFiltersDialog(true),
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const days = +daysInput;
      if (days > 0) {
        setDays(+days);
      }
    }, 250);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [daysInput]);

  const columns: GridColDef<Row>[] = [
    {
      field: "id",
      headerName: "Cod produs",
      width: 200,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Denumire produs",
      width: 200,
      flex: 1,
      sortable: false,
      renderCell({ row }) {
        return (
          <Tooltip title={row.name}>
            <div role="presentation" className="MuiDataGrid-cellContent">
              {row.name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "unitMeasurement",
      headerName: "U.M.",
      width: 50,
      sortable: false,
    },
    {
      field: "stock",
      headerName: "Cantitate stoc",
      type: "number",
      width: 110,
      sortable: false,
    },
    {
      field: "minStock",
      headerName: "Stoc minim",
      type: "number",
      width: 100,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      valueGetter(params) {
        return getProductStatusLocale(params.value);
      },
    },
    {
      field: "stockDroppedAt",
      headerName: "Data ultimă vânzare",
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      valueGetter(params) {
        return params.value ? params.value.toLocaleDateString() : "--";
      },
    },
  ];

  const fetchProducts = () => {
    setPageIsLoading(true);
    productRequest
      .findAll(pageSize, page * pageSize, searchText, {
        ...filtersDto,
        subtractDaysForUnsoldProducts: days,
      })
      .then((data) => {
        setRows(
          data.results.map((product) => ({
            id: product.code,
            name: product.name,
            unitMeasurement: product.unitMeasurement,
            stock: product.stock,
            minStock: product.minStock,
            status:
              product.status === ProductStatus.Initial ? "--" : product.status,
            stockDroppedAt: product.stockDroppedAt
              ? new Date(product.stockDroppedAt)
              : null,
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError("A apărut o eroare la încărcarea produselor!");
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [page, pageSize, searchText, filtersDto, days]);

  return (
    <>
      <Layout title="Marfă nevândută">
        <Grid container gap={1} alignItems="center">
          <span>Marfă nevândută de număr zile</span>
          <TextField
            size="small"
            id="days"
            name="days"
            label="Număr zile"
            type="number"
            inputProps={{ min: 1 }}
            value={daysInput}
            onChange={(e) => {
              setDaysInput(e.target.value);
            }}
          />
          <Button variant="contained" onClick={() => setDaysInput(30)}>
            30 zile
          </Button>
          <Button variant="contained" onClick={() => setDaysInput(45)}>
            45 zile
          </Button>
        </Grid>
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <StyledDataGrid
            sx={{
              ".MuiDataGrid-cell:focus-within": {
                outline: "none ",
              },
            }}
            disableColumnMenu
            getRowClassName={(params) => `product-status-${params.row.status}`}
            autoHeight
            rowCount={rowCount}
            rows={rows}
            columns={columns}
            paginationMode="server"
            pagination
            pageSizeOptions={[10, 25, 50]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(model) => {
              setPage(model.page);
              setPageSize(model.pageSize);
            }}
            loading={pageIsLoading}
            slots={{ toolbar }}
            filterMode="server"
            onFilterModelChange={(filterModel: GridFilterModel) => {
              setSearchText(filterModel.quickFilterValues?.join(" ") || "");
            }}
          />
        </Box>
      </Layout>
      <NecessaryGoodsPageFiltersDialog
        open={openFiltersDialog}
        onApplyFilters={(dto) => {
          setFiltersDto(dto);
          setOpenFiltersDialog(false);
        }}
        onCancel={() => setOpenFiltersDialog(false)}
      />
    </>
  );
}
