import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowId,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import Layout from "../../layout/Layout";
import { productRequest } from "../../api/ProductRequest";
import { Toast } from "../../helpers/Toast";
import Button from "@mui/material/Button";
import { ProductStatus } from "../../api/types/product";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import {
  Autocomplete,
  darken,
  Grid,
  Input,
  lighten,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../router/path.helper";
import { SupplyOrderPdf } from "../../helpers/pdf-renderer";
import TextField from "@mui/material/TextField";
import { Supplier } from "../../api/types/supplier";
import { supplierRequest } from "../../api/SupplierRequest";
import { useGetQuickSearchToolbar } from "../../components/common/QuickSearchToolbar";
import { styled } from "@mui/material/styles";
import NecessaryGoodsPageFiltersDialog, {
  FiltersDto,
} from "../../components/documents/dialogs/NecessaryGoodsPageFiltersDialog";
import { getProductStatusLocale } from "../../helpers/product";
import ExportProductsToPdfDialog from "../../components/documents/dialogs/ExportProductsToPdfDialog";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .product-status-${ProductStatus.Pending}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Rejected}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Accepted}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  [`& .product-status-${ProductStatus.Ordered}`]: {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
})) as typeof DataGrid;

interface Row {
  id: string;
  name: string;
  unitMeasurement: string;
  stock: string;
  minStock: number;
  status: string;
  supplier?: {
    id: number;
    name: string;
    address: string;
    email: string;
    phone: string;
  };
}

const defaultValueAvgQuantityTooltip = {
  selectedCode: "",
  value: 0,
};

const adaptSortByField = (field: string) => {
  const map = {
    id: "code",
  };

  return map[field as keyof typeof map] || field;
};

export default function NecessaryGoodsPage() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [rows, setRows] = useState<Row[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [supplyOrderQuantities, setSupplyOrderQuantities] = useState<
    Record<string, { quantity: number; rowData: Row }>
  >({});

  const [searchSupplierTextInput, setSearchSupplierTextInput] = useState("");
  const [supplierOptions, setSupplierOptions] = useState<Supplier[]>([]);
  const [
    selectedSupplierPerRowDictionary,
    setSelectedSupplierPerRowDictionary,
  ] = useState<Record<string, Supplier>>({});

  const [searchText, setSearchText] = useState("");

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [filtersDto, setFiltersDto] = useState<FiltersDto>({});
  const [avgQuantityTooltip, setAvgQuantityTooltip] = useState(
    defaultValueAvgQuantityTooltip,
  );
  const selectedCodeForAvgQuantity = avgQuantityTooltip.selectedCode;

  const toolbar = useGetQuickSearchToolbar({
    activeFiltersCount: filtersDto.supplierId
      ? Object.keys(filtersDto).length - 1
      : Object.keys(filtersDto).length,
    onFilterClick: () => setOpenFiltersDialog(true),
  });

  const [exportProductsToPdfFileId, setExportProductsToPdfFileId] =
    useState("");

  // useEffect(() => {
  //   if (!searchSupplierTextInput) {
  //     supplierRequest
  //       .findAll(10, 0)
  //       .then((data) => setSupplierOptions(data.results));
  //     return;
  //   }
  //
  //   const timeoutId = setTimeout(() => {
  //     supplierRequest
  //       .findAll(-1, 0, searchSupplierTextInput)
  //       .then((data) => setSupplierOptions(data.results));
  //   }, 500);
  //
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [searchSupplierTextInput]);

  useEffect(() => {
    supplierRequest
      .findAll(-1, 0)
      .then((data) => setSupplierOptions(data.results));
  }, []);

  useEffect(() => {
    if (!selectedCodeForAvgQuantity) {
      return;
    }
    productRequest
      .getAvgOrderedQuantity(selectedCodeForAvgQuantity)
      .then((avg) =>
        setAvgQuantityTooltip({
          selectedCode: selectedCodeForAvgQuantity,
          value: avg,
        }),
      )
      .catch(() => setAvgQuantityTooltip(defaultValueAvgQuantityTooltip));
  }, [selectedCodeForAvgQuantity]);

  const columns: GridColDef<Row>[] = [
    {
      field: "id",
      headerName: "Cod produs",
      width: 200,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Denumire produs",
      width: 200,
      flex: 1,
      sortable: true,
      renderCell({ row }) {
        return (
          <Tooltip title={row.name}>
            <div role="presentation" className="MuiDataGrid-cellContent">
              {row.name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "unitMeasurement",
      headerName: "U.M.",
      width: 80,
      sortable: true,
    },
    {
      field: "stock",
      headerName: "Cantitate stoc",
      type: "number",
      width: 140,
      sortable: true,
    },
    {
      field: "minStock",
      headerName: "Stoc minim",
      type: "number",
      width: 130,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      valueGetter(params) {
        return getProductStatusLocale(params.value);
      },
    },
    {
      field: "supplier",
      headerName: "Furnizor",
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      renderCell: ({ row }) => {
        return (
          <Autocomplete
            freeSolo
            clearOnBlur
            selectOnFocus
            handleHomeEndKeys
            forcePopupIcon
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            value={selectedSupplierPerRowDictionary[row.id] || row.supplier}
            onChange={(event, newValue) => {
              setSelectedSupplierPerRowDictionary((prev) => ({
                ...prev,
                [row.id]: newValue as Supplier,
              }));
            }}
            options={supplierOptions}
            // filterOptions={(options) => options}
            disableClearable
            getOptionLabel={(option) => `${(option as Supplier).name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Fără opțiuni"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            renderInput={({
              inputProps: { onChange, ...restInputProps },
              ...params
            }) => (
              <TextField
                {...params}
                inputProps={{
                  ...restInputProps,
                  placeholder: "Fără furnizor",
                  onChange: (...args) => {
                    setSearchSupplierTextInput(
                      // @ts-ignore
                      args[0].target.value,
                    );
                    // @ts-ignore
                    onChange(...args);
                  },
                }}
                size="small"
              />
            )}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Necesar",
      minWidth: 130,
      maxWidth: 130,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                if (supplyOrderQuantities[row.id]?.quantity <= 1) {
                  setRowSelectionModel((prev) =>
                    prev.filter((rowId) => rowId !== row.id),
                  );
                }
                setSupplyOrderQuantities((prev) => ({
                  ...prev,
                  [row.id]:
                    typeof prev[row.id]?.quantity === "number" &&
                    prev[row.id].quantity >= 1
                      ? {
                          quantity: --prev[row.id].quantity,
                          rowData: row,
                        }
                      : { quantity: 0, rowData: row },
                }));
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Tooltip
              title={`Cantitate comandată în medie: ${avgQuantityTooltip.value}`}
              open={Boolean(
                avgQuantityTooltip.selectedCode === row.id &&
                  avgQuantityTooltip.value,
              )}
            >
              <Input
                sx={{ width: 30 }}
                inputProps={{ min: 0 }}
                onKeyDown={(e) => e.stopPropagation()}
                onFocus={() =>
                  setAvgQuantityTooltip({ selectedCode: row.id, value: 0 })
                }
                onBlur={() =>
                  setAvgQuantityTooltip(defaultValueAvgQuantityTooltip)
                }
                onChange={(e) => {
                  if (+e.target.value >= 1) {
                    setRowSelectionModel((prev) =>
                      prev.includes(row.id) ? prev : [...prev, row.id],
                    );
                  } else {
                    setRowSelectionModel((prev) =>
                      prev.filter((rowId) => rowId !== row.id),
                    );
                  }
                  setSupplyOrderQuantities((prev) => ({
                    ...prev,
                    [row.id]: { quantity: +e.target.value, rowData: row },
                  }));
                }}
                value={supplyOrderQuantities[row.id]?.quantity || 0}
                type="number"
              />
            </Tooltip>
            <IconButton
              size="small"
              color="success"
              onClick={() => {
                setSupplyOrderQuantities((prev) => ({
                  ...prev,
                  [row.id]:
                    typeof prev[row.id]?.quantity === "number" &&
                    prev[row.id]?.quantity >= 1
                      ? { quantity: ++prev[row.id].quantity, rowData: row }
                      : { quantity: 1, rowData: row },
                }));
                setRowSelectionModel((prev) =>
                  prev.includes(row.id) ? prev : [...prev, row.id],
                );
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const fetchProducts = () => {
    setPageIsLoading(true);
    productRequest
      .findAll(
        pageSize,
        page * pageSize,
        searchText,
        filtersDto,
        sortModel[0]
          ? {
              sortBy: adaptSortByField(sortModel[0]!.field),
              sortByDirection: sortModel[0]!.sort!.toUpperCase() as any,
            }
          : undefined,
      )
      .then((data) => {
        setRows(
          data.results.map((product) => ({
            id: product.code,
            name: product.name,
            unitMeasurement: product.unitMeasurement,
            stock: product.stock,
            minStock: product.minStock,
            status:
              product.status === ProductStatus.Initial ? "--" : product.status,
            supplier: product.supplier,
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError("A apărut o eroare la încărcarea produselor!");
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [page, pageSize, searchText, filtersDto, sortModel]);

  const applyQuantitiesIfZeroBeforeSelection = (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    const filterCallback = (rowId: GridRowId) =>
      rows.some((row) => row.id === rowId);

    const newRowSelectionModelLimitedToVisibleRows =
      newRowSelectionModel.filter(filterCallback);
    const rowSelectionModelLimitedToVisibleRows =
      rowSelectionModel.filter(filterCallback);

    const selectAllClicked =
      newRowSelectionModelLimitedToVisibleRows.length === rows.length &&
      rowSelectionModelLimitedToVisibleRows.length < pageSize - 1;

    const selectionHasIncreased =
      newRowSelectionModelLimitedToVisibleRows.length >
      rowSelectionModelLimitedToVisibleRows.length;

    if (selectAllClicked) {
      setSupplyOrderQuantities((prev) => ({
        ...prev,
        ...rows.reduce((acc, row) => {
          if (!(prev[row.id]?.quantity > 0)) {
            acc = { ...acc, [row.id]: { quantity: 1, rowData: row } };
          }

          return acc;
        }, {}),
      }));
    }

    if (!selectAllClicked && selectionHasIncreased) {
      const selectedRow = rows.find(
        (row) =>
          row.id ===
          newRowSelectionModelLimitedToVisibleRows[
            newRowSelectionModelLimitedToVisibleRows.length - 1
          ],
      ) as Row;

      setSupplyOrderQuantities((prev) => ({
        ...prev,
        [selectedRow.id]: {
          quantity:
            prev[selectedRow.id]?.quantity > 0
              ? prev[selectedRow.id].quantity
              : 1,
          rowData: selectedRow,
        },
      }));
    }
  };

  return (
    <>
      <Layout title="Necesar marfă">
        <Grid container gap={1}>
          <Button
            variant="contained"
            disabled={pageIsLoading}
            onClick={() => {
              setPageIsLoading(true);
              productRequest
                .forceImport()
                .then(fetchProducts)
                .catch((e) => {
                  console.error(e);
                  Toast.showError(
                    "A apărut o eroare la actualizarea produselor!",
                  );
                  setPageIsLoading(false);
                });
            }}
          >
            Sincronizare produse
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={pageIsLoading || !rowSelectionModel.length}
            onClick={() => {
              const filteredData = rowSelectionModel
                .filter((rowId) => supplyOrderQuantities[rowId]?.quantity)
                .map((rowId) => {
                  const overwriteWithSelectedSupplier =
                    selectedSupplierPerRowDictionary[rowId];
                  const supplyOrderQuantity = supplyOrderQuantities[rowId];

                  if (overwriteWithSelectedSupplier) {
                    supplyOrderQuantity.rowData.supplier =
                      overwriteWithSelectedSupplier;
                  }
                  return supplyOrderQuantity;
                })
                .filter((row) => row.rowData.supplier);

              const supplyOrdersDictionary = filteredData.reduce<
                Record<number, SupplyOrderPdf>
              >((acc, entry) => {
                if (acc[entry.rowData.supplier!.id]) {
                  acc[entry.rowData.supplier!.id] = {
                    supplier: entry.rowData.supplier!,
                    products: [
                      ...acc[entry.rowData.supplier!.id].products,
                      {
                        name: entry.rowData.name,
                        code: entry.rowData.id,
                        unitMeasurement: entry.rowData.unitMeasurement,
                        quantity: entry.quantity,
                      },
                    ],
                  };
                } else {
                  acc[entry.rowData.supplier!.id] = {
                    supplier: entry.rowData.supplier!,
                    products: [
                      {
                        name: entry.rowData.name,
                        code: entry.rowData.id,
                        unitMeasurement: entry.rowData.unitMeasurement,
                        quantity: entry.quantity,
                      },
                    ],
                  };
                }
                return acc;
              }, {});

              if (!Object.keys(supplyOrdersDictionary).length) {
                Toast.showError(
                  "Niciun produs selectat nu are atribuit un furnizor pentru a genera fișa! Selectați un furnizor la produse.",
                );
                return;
              }

              navigate(PATH.GENERATE_SUPPLY_ORDERS, {
                state: { supplyOrders: Object.values(supplyOrdersDictionary) },
              });
            }}
          >
            Generare fișă comandă
          </Button>
          <Button
            variant="contained"
            disabled={pageIsLoading || !filtersDto.supplierId}
            color="warning"
            onClick={() => {
              setPageIsLoading(true);
              productRequest
                .exportToPdf(
                  pageSize,
                  page * pageSize,
                  searchText,
                  filtersDto,
                  sortModel[0]
                    ? {
                        sortBy: adaptSortByField(sortModel[0]!.field),
                        sortByDirection:
                          sortModel[0]!.sort!.toUpperCase() as any,
                      }
                    : undefined,
                )
                .then((res) => {
                  setExportProductsToPdfFileId(res.id);
                })
                .catch((e) => {
                  console.error(e);
                  Toast.showError("A apărut o eroare la exportul produselor!");
                })
                .finally(() => {
                  setPageIsLoading(false);
                });
            }}
          >
            Export produse
          </Button>
          <Button
            variant="contained"
            disabled={pageIsLoading || filtersDto.soldInCurrentWeek}
            color="info"
            onClick={() => {
              setFiltersDto((prev) => ({ ...prev, soldInCurrentWeek: true }));
            }}
          >
            Produse vândute săptămâna curentă
          </Button>
        </Grid>
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <StyledDataGrid
            sx={{
              ".MuiDataGrid-cell:focus-within": {
                outline: "none ",
              },
            }}
            getRowClassName={(params) => `product-status-${params.row.status}`}
            disableColumnMenu
            keepNonExistentRowsSelected
            autoHeight
            rowCount={rowCount}
            rows={rows}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            pagination
            pageSizeOptions={[10, 25, 50]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(model) => {
              setPage(model.page);
              setPageSize(model.pageSize);
            }}
            loading={pageIsLoading}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              applyQuantitiesIfZeroBeforeSelection(newRowSelectionModel);
              setRowSelectionModel(newRowSelectionModel);
            }}
            slots={{ toolbar }}
            filterMode="server"
            onFilterModelChange={(filterModel: GridFilterModel) => {
              setSearchText(filterModel.quickFilterValues?.join(" ") || "");
            }}
          />
        </Box>
      </Layout>
      <NecessaryGoodsPageFiltersDialog
        open={openFiltersDialog}
        onApplyFilters={(dto) => {
          setFiltersDto(dto);
          setOpenFiltersDialog(false);
        }}
        onCancel={() => setOpenFiltersDialog(false)}
      />
      <ExportProductsToPdfDialog
        supplier={filtersDto.supplier}
        fileId={exportProductsToPdfFileId}
        onClose={() => setExportProductsToPdfFileId("")}
      />
    </>
  );
}
