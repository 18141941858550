import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import theme from "./theme";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ToolbarProvider from "./contexts/ToolbarProvider";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./redux/listeners";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9a2617e4ef83794fe074f271979440b7@o4506151938949120.ingest.sentry.io/4506151940390912",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          "input[type=number]": { MozAppearance: "textfield" },
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            { margin: 0, WebkitAppearance: "none" },
        }}
      />

      <BrowserRouter>
        <Provider store={store}>
          <ToolbarProvider>
            <App />
            <ToastContainer />
          </ToolbarProvider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
