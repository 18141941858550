import AbstractRequest from "./AbstractRequest";
import { ApiMethod } from "./constants";
import {
  CreateUserRequestBody,
  UpdateUserRequestBody,
  User,
} from "./types/user";

export class UserRequest extends AbstractRequest {
  get apiResource(): string {
    return "user";
  }

  get routes(): Record<string, string> {
    return {
      FIND_ALL: `${this.apiRoute}`,
      CREATE: `${this.apiRoute}`,
      UPDATE: `${this.apiRoute}/`,
      DELETE: `${this.apiRoute}/`,
    };
  }

  async findAll(
    limit: number,
    offset: number,
  ): Promise<{ results: User[]; total: number }> {
    const queryParams = [
      { key: "limit", value: `${limit}` },
      { key: "offset", value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      this.routes.FIND_ALL + this.buildQueryParams(queryParams),
    );

    return result.data;
  }

  async create(body: CreateUserRequestBody): Promise<unknown> {
    const result = await this.request(ApiMethod.POST, this.routes.CREATE, body);

    return result.data;
  }

  async update(id: string, body: UpdateUserRequestBody): Promise<unknown> {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE + id,
      body,
    );

    return result.data;
  }

  async delete(id: string): Promise<unknown> {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE + id,
    );

    return result.data;
  }
}

export const userRequest = new UserRequest();
