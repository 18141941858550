import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

export const ToolbarContext = createContext<{
  toolbarPortalNode: HTMLElement | null;
  setToolbarPortalNode: CallbackFunction;
}>({ toolbarPortalNode: null, setToolbarPortalNode: () => null });

export default function ToolbarProvider({ children }: { children: ReactNode }) {
  const [toolbarPortalNode, setToolbarPortalNode] =
    useState<HTMLElement | null>(null);

  const optimizedSetToolbarPortalNode = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        setToolbarPortalNode(node);
      }
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      toolbarPortalNode,
      setToolbarPortalNode: optimizedSetToolbarPortalNode,
    }),
    [toolbarPortalNode, optimizedSetToolbarPortalNode],
  );

  return (
    <ToolbarContext.Provider value={contextValue}>
      {children}
    </ToolbarContext.Provider>
  );
}
