import * as React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { fileRequest } from "../../../api/FileRequest";
import { Supplier } from "../../../api/types/supplier";
import DownloadIcon from "@mui/icons-material/Download";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import { ImportExport } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

interface Props {
  supplier?: Supplier;
  fileId: string;
  onClose: CallbackFunction;
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export default function ExportProductsToPdfDialog({
  supplier,
  fileId,
  onClose,
}: Props) {
  const open = Boolean(fileId);

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ImportExport sx={{ fontSize: "75px" }} />
        <DialogTitle id="alert-dialog-title">Export produse</DialogTitle>
        <DialogActions
          sx={{
            minWidth: 520,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="info"
            sx={{ gap: 1 }}
            onClick={() => {
              const a = document.createElement("a");
              a.href = fileRequest.getDownloadLink(fileId);
              a.target = "_blank";
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            <DownloadIcon /> Descărcă
          </Button>
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="success"
            sx={{ gap: 1 }}
            onClick={() => {
              const a = document.createElement("a");
              a.href = `https://wa.me/${
                supplier?.phone.startsWith("07")
                  ? `+4${supplier?.phone}`
                  : supplier?.phone
              }?text=${fileRequest.getDownloadLink(fileId)}`;
              a.target = "_blank";
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            <WhatsAppIcon /> Whatsapp
          </Button>
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="warning"
            sx={{ gap: 1 }}
            onClick={() => {
              const a = document.createElement("a");
              a.href = `mailto:${supplier?.email}?subject=Export%20Produse%20-%20Modul%20Apex%20Vending&body=${fileRequest.getDownloadLink(
                fileId,
              )}`;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            <MailIcon />
            Email
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
