import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Badge, Grid, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import { useCallback } from "react";

type Props =
  | {
      onFilterClick: CallbackFunction;
      activeFiltersCount: number;
    }
  | { onFilterClick?: never; activeFiltersCount?: never };

export default function QuickSearchToolbar({
  onFilterClick,
  activeFiltersCount,
}: Props) {
  return (
    <Grid
      container
      sx={{
        p: 0.5,
        pb: 0,
      }}
      justifyContent="space-between"
    >
      <GridToolbarQuickFilter debounceMs={250} />
      {onFilterClick && (
        <Button size="small" onClick={onFilterClick}>
          <Stack direction="row" gap={1.5}>
            <Badge badgeContent={activeFiltersCount} color="primary">
              <FilterListIcon fontSize="small" />
            </Badge>
            Filtre
          </Stack>
        </Button>
      )}
    </Grid>
  );
}

export const useGetQuickSearchToolbar = ({
  onFilterClick,
  activeFiltersCount,
}: Props) => {
  const toolbar = useCallback(() => {
    return (
      <Grid
        container
        sx={{
          p: 0.5,
          pb: 0,
        }}
        justifyContent="space-between"
      >
        <GridToolbarQuickFilter debounceMs={250} />
        {onFilterClick && (
          <Button size="small" onClick={onFilterClick}>
            <Stack direction="row" gap={1.5}>
              <Badge badgeContent={activeFiltersCount} color="primary">
                <FilterListIcon fontSize="small" />
              </Badge>
              Filtre
            </Stack>
          </Button>
        )}
      </Grid>
    );
  }, [activeFiltersCount]);

  return toolbar;
};
