import AbstractClientStore from "./AbstractClientStore";

const LOCAL_STORAGE_TOKEN_KEY = "token";

class AuthClientStore extends AbstractClientStore {
  static getToken(): string | null {
    return this.storage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  static setToken(token: string): void {
    this.storage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  static removeToken(): void {
    this.storage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }
}

export default AuthClientStore;
