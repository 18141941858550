import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import EditSupplierOrderForm from "../forms/EditSupplierOrderForm";
import * as React from "react";
import { SupplyOrderPdf } from "../../../helpers/pdf-renderer";
import { format } from "date-fns";

export type EditSupplierOrder = SupplyOrderPdf | null;

interface Props {
  supplierOrder: EditSupplierOrder;
  onClose: CallbackFunction;
  onSuccess: CallbackFunction;
}

export default function EditSupplierOrderDialog({
  supplierOrder,
  onClose,
  onSuccess,
}: Props) {
  const isOpen = Boolean(supplierOrder);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (window.confirm("Esti sigur că vrei să închizi?")) {
          onClose();
        }
      }}
    >
      <DialogTitle>
        Modifică document - {supplierOrder?.id}/
        {format(supplierOrder?.createdAt || new Date(), "dd.MM.yyyy")} -{" "}
        {supplierOrder?.supplier.name}
      </DialogTitle>
      <DialogContent>
        <EditSupplierOrderForm
          onCancel={() => {
            if (window.confirm("Esti sigur că vrei să închizi?")) {
              onClose();
            }
          }}
          supplierId={supplierOrder?.supplier.id || 0}
          supplierOrderId={supplierOrder?.id || 0}
          initialValues={
            supplierOrder?.products.map((product) => ({
              id: Math.random(),
              product: {
                code: product.code,
                name: product.name,
                supplierId: product.supplierId || 0,
              },
              quantity: product.quantity,
            })) || []
          }
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
