import {
  Document,
  Image,
  Page,
  pdf,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import logo from "../assets/bulldogu-logo.png";
import { format } from "date-fns";
import React from "react";
import { saveAs } from "file-saver";
import { SupplierOrder } from "../api/types/supplier-order";
import { fileRequest } from "../api/FileRequest";

const styles = StyleSheet.create({
  viewer: {
    width: "800px",
    height: window.innerHeight - 300,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 25px 25px 25px",
  },
  bold: {
    fontFamily: "Times-Bold",
  },
  alignCenter: {
    textAlign: "center",
  },
  headerSection: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    color: "#565956",
    fontFamily: "Times-Roman",
  },
  title: {
    textAlign: "center",
    fontFamily: "Times-Bold",
    fontSize: 18,
  },
  subtitleSection: {
    marginTop: 20,
  },
  subtitleDescriptionRow: {
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 14,
  },
  subtitleDescriptionColumn: {
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
  },
  subtitleRedText: {
    fontFamily: "Times-Roman",
    color: "#D0312D",
  },
  supplierSection: {
    marginTop: 20,
    fontFamily: "Times-Roman",
    fontSize: 14,
  },
  beforeTable: {
    fontFamily: "Times-Roman",
    fontSize: 14,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  table: {},
  afterTable: {
    fontFamily: "Times-Roman",
    fontSize: 14,
    marginTop: 20,
    marginLeft: 20,
  },
  cellHeader: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "18.5%",
    backgroundColor: "#E5E4E2",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellHeaderFirst: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "8.5%",
    backgroundColor: "#E5E4E2",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellHeaderProductName: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "38%",
    backgroundColor: "#E5E4E2",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellHeaderLast: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "16.5%",
    backgroundColor: "#E5E4E2",
  },
  cell: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "18.5%",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellFirst: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "8.5%",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellProductName: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "38%",
    borderRightColor: "#848884",
    borderRightWidth: 1,
  },
  cellLast: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    padding: 8,
    width: "16.5%",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    borderColor: "#848884",
    borderWidth: 1,
    alignItems: "center",
    textAlign: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#848884",
    borderBottomWidth: 1,
    borderLeftColor: "#848884",
    borderLeftWidth: 1,
    borderRightColor: "#848884",
    borderRightWidth: 1,
    alignItems: "center",
    textAlign: "center",
  },
  footerSection: {
    fontFamily: "Times-Roman",
    fontSize: 14,
    marginTop: 160,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface SupplyOrderPdf {
  id?: number;
  createdAt?: Date;
  supplier: {
    id: number;
    name: string;
    address: string;
    email: string;
    phone: string;
  };
  products: {
    name: string;
    code: string;
    unitMeasurement: string;
    quantity: number;
    supplierId?: number;
  }[];
}

interface Options {
  withPdfViewer?: boolean;
  withToolbar?: boolean;
}

export const getSupplierOrderPdf = (
  order: SupplyOrderPdf,
  options?: Options,
) => {
  const document = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerSection}>
          <View>
            <View style={styles.headerRow}>
              <Text>BULLDOGU DEVELOPMENT SRL</Text>
            </View>

            <View style={styles.headerRow}>
              <Text style={styles.bold}>Reg. com: </Text>
              <Text>J23/4238/2015</Text>
            </View>

            <View style={styles.headerRow}>
              <Text style={styles.bold}>CIF: </Text>
              <Text>RO35276980</Text>
            </View>

            <View style={styles.headerRow}>
              <Text style={styles.bold}>Adresa: </Text>
              <Text>Sos. Snagov 32, Snagov, Jud. Ilfov</Text>
            </View>

            <View style={styles.headerRow}>
              <Text style={styles.bold}>Tel.: </Text>
              <Text>0780.860.298</Text>
            </View>

            <View style={styles.headerRow}>
              <Text style={styles.bold}>Email: </Text>
              <Text>bulldogudevelopment@yahoo.com</Text>
            </View>
          </View>

          <Image src={logo} style={{ height: 80 }} />
        </View>
        <Text style={styles.title}>
          Fisa de comanda nr. {order.id || "ZZ"} /{" "}
          {format(order.createdAt || new Date(), "dd.MM.yyyy")}
        </Text>

        <View style={styles.subtitleSection}>
          <View style={styles.subtitleDescriptionRow}>
            <Text style={styles.bold}>Termen de livrare: </Text>
            <Text style={styles.subtitleRedText}>Urgent</Text>
          </View>

          <View style={styles.subtitleDescriptionRow}>
            <Text style={styles.bold}>Adresa de livrare: </Text>
            <Text>Sos. Snagov 32, Snagov, Jud. Ilfov</Text>
          </View>

          <View style={styles.subtitleDescriptionColumn}>
            <Text style={styles.bold}>Conditii de livrare:</Text>
            <Text style={styles.alignCenter}>
              Produsele trebuie sa vina insotite de documentele aferente: cod de
              bare, factura si documente de calitate, respectiv garantia
              produselor
            </Text>
          </View>
        </View>

        <View style={styles.supplierSection}>
          <Text style={styles.bold}>Catre:</Text>
          <Text>{order.supplier.name}</Text>
          <Text>{order.supplier.address}</Text>
          <Text>{order.supplier.email}</Text>
          <Text>{order.supplier.phone}</Text>
        </View>

        <Text style={styles.beforeTable}>
          Prin prezenta, solicitam comanda urmatoarelor produse:
        </Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.cellHeaderFirst}>Nr. Crt</Text>
            <Text style={styles.cellHeaderProductName}>Denumire produs</Text>
            <Text style={styles.cellHeader}>Cod produs</Text>
            <Text style={styles.cellHeader}>Cantitate comandata</Text>
            <Text style={styles.cellHeaderLast}>Unitate de masura</Text>
          </View>
          {order.products.map((product, index) => (
            <View style={styles.tableRow} key={product.code}>
              <Text style={styles.cellFirst}>{index + 1}</Text>
              <Text style={styles.cellProductName}>{product.name}</Text>
              <Text style={styles.cell}>{product.code}</Text>
              <Text style={styles.cell}>{product.quantity.toFixed(2)}</Text>
              <Text style={styles.cellLast}>{product.unitMeasurement}</Text>
            </View>
          ))}
        </View>

        <Text style={styles.afterTable}>
          Rugam confirmare primire comanda in scris.
        </Text>

        <View style={styles.footerSection}>
          <Text style={styles.bold}>
            Data: {format(order.createdAt || new Date(), "dd.MM.yyyy")}
          </Text>
          <Text style={styles.bold}>Semnatura administrator</Text>
        </View>
      </Page>
    </Document>
  );

  if (options?.withPdfViewer) {
    return (
      <PDFViewer
        style={styles.viewer}
        showToolbar={Boolean(options.withToolbar)}
      >
        {document}
      </PDFViewer>
    );
  }

  return document;
};

export const downloadSupplierOrderPdf = async (order: SupplyOrderPdf) => {
  const document = getSupplierOrderPdf(order);
  const blob = await pdf(document).toBlob();

  saveAs(blob, `Comanda ${order.supplier.name}`);
};

export const printSupplierOrderPdf = async (order: SupplyOrderPdf) => {
  const document = getSupplierOrderPdf(order);
  const blob = await pdf(document).toBlob();
  const url = URL.createObjectURL(blob);

  const existingIframe = window.document.body.querySelector("#print-iframe");
  if (existingIframe) {
    window.document.body.removeChild(existingIframe);
  }

  const iframe = window.document.createElement("iframe");
  window.document.body.appendChild(iframe);

  iframe.id = "print-iframe";
  iframe.style.display = "none";
  iframe.src = url;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow?.print();
      URL.revokeObjectURL(url);
    }, 0);
  };
};

export const uploadSupplierOrderPdf = async (order: SupplyOrderPdf) => {
  const document = getSupplierOrderPdf(order);
  const blob = await pdf(document).toBlob();

  return fileRequest.upload(
    new File([blob], `Comanda ${order.supplier.name}.pdf`, {
      type: "application/pdf",
    }),
  );
};

export const convertApiOrderToPdfOrder = (
  order: SupplierOrder,
): SupplyOrderPdf => ({
  id: order.id,
  createdAt: new Date(order.createdAt),
  supplier: order.supplier,
  products: order.rows.map((row) => ({
    name: row.product.name,
    quantity: +row.quantity,
    code: row.productId,
    unitMeasurement: row.product.unitMeasurement,
    supplierId: row.product.supplierId || 0,
  })),
});

export enum SenderType {
  Email = "email",
  Whatsapp = "whatsapp",
}

const getAHref = (
  senderType: SenderType,
  toOrderSupplyOrder: SupplyOrderPdf,
  fileId: string,
) => {
  if (senderType === SenderType.Email) {
    return `mailto:${toOrderSupplyOrder?.supplier
      .email}?subject=Comanda%20Produse%20${toOrderSupplyOrder?.id}%20-%20Modul%20Apex%20Vending&body=Buna%20ziua!%0D%0A%0D%0APrin%20prezentul%20e-mail%20solicitam%20livrarea%20urmatoarelor%20produse%20conform%20fisei%20de%20comanda%20ce%20poate%20fi%20descarcata%20la%20urmatorul%20link%3A%0D%0A%0D%0ADESCARCA%3A%20${fileRequest.getDownloadLink(
      fileId,
    )}%0D%0A%0D%0AProdusele%20trebuie%20sa%20vin%C4%83%20inso%C8%9Bite%20de%20documentele%20aferente%3A%20cod%20de%20bare%2C%20factur%C4%83%20%C8%99i%20documente%20de%20calitate%20%C8%99i%20garan%C8%9Bie.%0D%0A%0D%0ARugam%20confirmarea%20primirii%20comenzii%20prin%20raspuns%20la%20acest%20mail.%0D%0A%0D%0ACu%20stima%2C%0D%0AEchipa%20Modul%20Apex%20Vending`;
  }

  return `https://wa.me/${
    toOrderSupplyOrder?.supplier.phone.startsWith("07")
      ? `+4${toOrderSupplyOrder?.supplier.phone}`
      : toOrderSupplyOrder?.supplier.phone
  }?text=Buna%20ziua!%0D%0A%0D%0APrin%20prezentul%20mesaj%20solicitam%20livrarea%20urmatoarelor%20produse%20conform%20fisei%20de%20comanda%20ce%20poate%20fi%20descarcata%20la%20urmatorul%20link%3A%0D%0A%0D%0ADESCARCA%3A%20${fileRequest.getDownloadLink(
    fileId,
  )}%0D%0A%0D%0AProdusele%20trebuie%20sa%20vin%C4%83%20inso%C8%9Bite%20de%20documentele%20aferente%3A%20cod%20de%20bare%2C%20factur%C4%83%20%C8%99i%20documente%20de%20calitate%20%C8%99i%20garan%C8%9Bie.%0D%0A%0D%0ARugam%20confirmarea%20primirii%20comenzii%20prin%20raspuns%20la%20acest%20mesaj.%0D%0A%0D%0ACu%20stima%2C%0D%0AEchipa%20Modul%20Apex%20Vending`;
};

export const sendSupplierOrderPdf = (
  senderType: SenderType,
  toOrderSupplyOrder: SupplyOrderPdf,
  fileId: string,
) => {
  const a = document.createElement("a");
  a.href = getAHref(senderType, toOrderSupplyOrder, fileId);
  if (senderType === SenderType.Whatsapp) {
    a.target = "_blank";
  }
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
