import Layout from "../../layout/Layout";
import { capitalize, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CreateUpdateUserDialog from "../../components/users/dialogs/CreateUpdateUserDialog";
import { userRequest } from "../../api/UserRequest";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { User } from "../../api/types/user";
import ConfirmDeleteUserDialog from "../../components/users/dialogs/ConfirmDeleteUserDialog";
import { useAppSelector } from "../../hooks/redux";
import { authUserSelector } from "../../redux/slices/selectors";
import { Toast } from "../../helpers/Toast";

type Row = User;

export default function UsersPage() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<Row[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [toDeleteId, setToDeleteId] = useState("");
  const [openCreateUpdateUserDialog, setOpenCreateUpdateUserDialog] =
    useState(false);
  const [editUser, setEditUser] = useState<Row | undefined>(undefined);

  const authUser = useAppSelector(authUserSelector);

  const fetchData = () => {
    setPageIsLoading(true);
    userRequest
      .findAll(pageSize, page * pageSize)
      .then((data) => {
        setRows(data.results);
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError("A apărut o eroare la încărcarea utilizatorilor!");
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  const columns: GridColDef<Row>[] = [
    {
      field: "username",
      headerName: "Nume utilizator",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "firstName",
      headerName: "Prenume",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Nume",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Rol",
      width: 150,
      flex: 1,
      sortable: false,
      valueGetter(params) {
        return capitalize(params.value);
      },
    },
    {
      field: "action",
      headerName: "Acțiune",
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              color="success"
              onClick={() => {
                setEditUser(row);
                setOpenCreateUpdateUserDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              disabled={authUser.id === row.id}
              onClick={() => setToDeleteId(row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Layout title="Utilizatori">
      <Grid>
        <Grid container gap={1}>
          <Button
            variant="contained"
            disabled={pageIsLoading}
            onClick={() => setOpenCreateUpdateUserDialog(true)}
          >
            Adaugă utilizator
          </Button>
        </Grid>
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <DataGrid
            sx={{
              ".MuiDataGrid-cell:focus-within": {
                outline: "none ",
              },
            }}
            disableColumnMenu
            rowCount={rowCount}
            rows={rows}
            columns={columns}
            paginationMode="server"
            pagination
            pageSizeOptions={[10, 25, 50]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(model) => {
              setPage(model.page);
              setPageSize(model.pageSize);
            }}
            loading={pageIsLoading}
            disableRowSelectionOnClick
          />
          <CreateUpdateUserDialog
            user={editUser}
            open={openCreateUpdateUserDialog}
            onClose={() => {
              setOpenCreateUpdateUserDialog(false);
              if (editUser) {
                setEditUser(undefined);
              }
            }}
            onRequestSuccess={fetchData}
          />
          <ConfirmDeleteUserDialog
            toDeleteId={toDeleteId}
            onDeleteSuccess={fetchData}
            onResetToDeleteId={() => setToDeleteId("")}
          />
        </Box>
      </Grid>
    </Layout>
  );
}
