import * as React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Button from "@mui/material/Button";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { Toast } from "../../../helpers/Toast";

interface Props {
  toAcceptId: number;
  onAcceptSuccess: CallbackFunction;
  onResetToAcceptId: CallbackFunction;
}

export default function ConfirmAcceptDocumentDialog({
  toAcceptId,
  onAcceptSuccess,
  onResetToAcceptId,
}: Props) {
  const openAcceptConfirmation = Boolean(toAcceptId);

  return (
    <Dialog open={openAcceptConfirmation}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DoneOutlineIcon sx={{ fontSize: "75px" }} />
        <DialogTitle id="alert-dialog-title">
          Sunteți sigur că doriți să aprobați acest document?
        </DialogTitle>
        <DialogActions
          sx={{
            minWidth: 520,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="success"
            onClick={() => {
              supplierOrderRequest
                .accept(toAcceptId)
                .then(() => {
                  onAcceptSuccess();
                  Toast.showSuccess("Document aprobat cu succes.");
                })
                .catch((e) => {
                  console.error(e);
                  Toast.showError(
                    "A apărut o eroare. Vă rugăm încercați mai târziu.",
                  );
                })
                .finally(onResetToAcceptId);
            }}
            autoFocus
          >
            Aprobă
          </Button>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={onResetToAcceptId}
          >
            Renunță
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
