import * as React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import BookIcon from "@mui/icons-material/Book";
import Button from "@mui/material/Button";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { Toast } from "../../../helpers/Toast";
import {
  SenderType,
  sendSupplierOrderPdf,
  SupplyOrderPdf,
  uploadSupplierOrderPdf,
} from "../../../helpers/pdf-renderer";

interface Props {
  toOrderSupplyOrder: SupplyOrderPdf | null;
  onOrderSuccess: CallbackFunction;
  onResetToOrderId: CallbackFunction;
  senderType: SenderType;
}

export default function ConfirmOrderDocumentDialog({
  toOrderSupplyOrder,
  onOrderSuccess,
  onResetToOrderId,
  senderType,
}: Props) {
  const openOrderConfirmation = Boolean(toOrderSupplyOrder);

  return (
    <Dialog open={openOrderConfirmation}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BookIcon sx={{ fontSize: "75px" }} />
        <DialogTitle id="alert-dialog-title">
          Sunteți sigur că doriți să comandați produsele din acest document?
        </DialogTitle>
        <DialogActions
          sx={{
            minWidth: 520,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="warning"
            onClick={() => {
              uploadSupplierOrderPdf(toOrderSupplyOrder!)
                .then(({ id: fileId }) =>
                  supplierOrderRequest
                    .order(toOrderSupplyOrder?.id!, fileId)
                    .then(() => {
                      onOrderSuccess();
                      Toast.showSuccess("Document aprobat cu succes.");
                    })
                    .then(() => {
                      sendSupplierOrderPdf(
                        senderType,
                        toOrderSupplyOrder!,
                        fileId,
                      );
                    }),
                )
                .catch((e) => {
                  console.error(e);
                  Toast.showError(
                    "A apărut o eroare. Vă rugăm încercați mai târziu.",
                  );
                })
                .finally(onResetToOrderId);
            }}
            autoFocus
          >
            Comandă
          </Button>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={onResetToOrderId}
          >
            Renunță
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
