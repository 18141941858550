import Layout from "../../layout/Layout";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Navigate, useLocation } from "react-router-dom";
import { PATH } from "../../router/path.helper";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { supplierOrderRequest } from "../../api/SupplierOrderRequest";
import { Toast } from "../../helpers/Toast";
import {
  getSupplierOrderPdf,
  SupplyOrderPdf,
} from "../../helpers/pdf-renderer";

export default function GenerateSupplyOrdersPage() {
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const location = useLocation();
  const [supplyOrders, setSupplyOrders] = useState<SupplyOrderPdf[]>(
    location.state?.supplyOrders || [],
  );
  const [viewSupplyOrderIndex, setViewSupplyOrderIndex] = useState(0);
  const currentOrder = supplyOrders[viewSupplyOrderIndex];

  const removeCurrentOrderFromList = () => {
    if (viewSupplyOrderIndex === supplyOrders.length - 1) {
      setViewSupplyOrderIndex(viewSupplyOrderIndex - 1);
    }
    setSupplyOrders(
      supplyOrders.filter(
        (order) => order.supplier.id !== currentOrder.supplier.id,
      ),
    );
  };

  if (!supplyOrders?.length) {
    return <Navigate to={PATH.NECESSARY_GOODS} />;
  }

  return (
    <Layout title="Generare fișă comandă">
      <Grid container alignItems="center" justifyContent="center" mb={4}>
        {getSupplierOrderPdf(currentOrder, { withPdfViewer: true })}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        mb={3}
      >
        <Grid item>
          <IconButton
            disabled={requestIsLoading}
            onClick={() =>
              setViewSupplyOrderIndex((prev) => (prev - 1 < 0 ? 0 : --prev))
            }
          >
            <ArrowLeft sx={{ width: 64, height: 64 }} />
          </IconButton>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                color="success"
                sx={{ width: 310 }}
                onClick={() => {
                  setRequestIsLoading(true);
                  supplierOrderRequest
                    .create({
                      supplierId: currentOrder.supplier.id,
                      rows: currentOrder.products.map((product) => ({
                        productCode: product.code,
                        quantity: product.quantity,
                      })),
                    })
                    .then(() => {
                      removeCurrentOrderFromList();
                      Toast.showSuccess("Document generat cu succes.");
                    })
                    .catch((e) => {
                      console.error(e);
                      Toast.showError(
                        "A apărut o eroare. Vă rugăm încercați mai târziu.",
                      );
                    })
                    .finally(() => setRequestIsLoading(false));
                }}
              >
                Generează și trimite spre aprobare
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={requestIsLoading}
                variant="contained"
                color="error"
                sx={{ width: 310 }}
                onClick={removeCurrentOrderFromList}
              >
                Anulează generarea
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            disabled={requestIsLoading}
            onClick={() =>
              setViewSupplyOrderIndex((prev) =>
                prev + 1 > supplyOrders.length - 1
                  ? supplyOrders.length - 1
                  : ++prev,
              )
            }
          >
            <ArrowRight sx={{ width: 64, height: 64 }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
        <Typography component="h6" variant="h6">
          {viewSupplyOrderIndex + 1}/{supplyOrders.length}
        </Typography>
      </Grid>
    </Layout>
  );
}
