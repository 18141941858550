import { Supplier } from "./supplier";
import { Product } from "./product";

export enum SupplierOrderStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Ordered = "ordered",
}

export interface SupplierOrderRow {
  id: string;
  productId: string;
  quantity: string;
  product: Product;
}

export interface SupplierOrder {
  id: number;
  createdAt: string;
  lastStatusDate?: string;
  lastStatusNote?: string;
  status: SupplierOrderStatus;
  rows: SupplierOrderRow[];
  supplier: Supplier;
  fileId?: string;
}

export interface GenerateSupplierOrderRequestBody {
  rows: { productCode: string; quantity: number }[];
  supplierId: number;
}

export interface EditSupplierOrderRequestBody {
  rows: { productCode: string; quantity: number }[];
}

export interface ChangeStatusRequestBody {
  note: string;
}
