import * as React from "react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NecessaryGoodsPage from "./pages/manager/NecessaryGoodsPage";
import ManagerDashboardPage from "./pages/manager/DashboardPage";
import AdminDashboardPage from "./pages/admin/DashboardPage";
import LoginPage from "./pages/LoginPage";
import { PATH } from "./router/path.helper";
import { RequireAuth } from "./decorators/RequireAuth";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { me } from "./redux/slices/auth";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import GenerateSupplyOrdersPage from "./pages/manager/GenerateSupplyOrdersPage";
import ManagerDocumentsPage from "./pages/manager/DocumentsPage";
import AdminDocumentsPage from "./pages/admin/DocumentsPage";
import { isAdminSelector } from "./redux/slices/selectors";
import PendingDocumentsPage from "./pages/admin/PendingDocumentsPage";
import UsersPage from "./pages/admin/UsersPage";
import UnsoldGoodsPage from "./pages/common/UnsoldGoodsPage";

export default function App() {
  const dispatch: any = useAppDispatch();
  const [showAppContent, setShowAppContent] = useState(false);
  const isAdmin = useAppSelector(isAdminSelector);

  useEffect(() => {
    dispatch(me())
      .unwrap()
      .finally(() => setShowAppContent(true));
  }, []);

  return showAppContent ? (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path={PATH.LOGIN} element={<LoginPage />} />
      <Route
        path={PATH.DASHBOARD}
        element={
          <RequireAuth>
            {isAdmin ? <AdminDashboardPage /> : <ManagerDashboardPage />}
          </RequireAuth>
        }
      />
      <Route
        path={PATH.NECESSARY_GOODS}
        element={
          <RequireAuth>
            <NecessaryGoodsPage />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.UNSOLD_GOODS}
        element={
          <RequireAuth>
            <UnsoldGoodsPage />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.GENERATE_SUPPLY_ORDERS}
        element={
          <RequireAuth>
            <GenerateSupplyOrdersPage />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.DOCUMENTS}
        element={
          <RequireAuth>
            {isAdmin ? <AdminDocumentsPage /> : <ManagerDocumentsPage />}
          </RequireAuth>
        }
      />
      {isAdmin && (
        <Route
          path={PATH.PENDING_DOCUMENTS}
          element={
            <RequireAuth>
              <PendingDocumentsPage />
            </RequireAuth>
          }
        />
      )}
      {isAdmin && (
        <Route
          path={PATH.USERS}
          element={
            <RequireAuth>
              <UsersPage />
            </RequireAuth>
          }
        />
      )}
    </Routes>
  ) : (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={72} />
    </Box>
  );
}
