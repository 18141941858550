import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Supplier } from "../../../api/types/supplier";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { supplierRequest } from "../../../api/SupplierRequest";
import { ProductStatus } from "../../../api/types/product";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

interface Filters {
  supplier: Supplier | null;
  minStock: number | "";
  maxStock: number | "";
  status: ProductStatus | "";
  soldInCurrentWeek: boolean;
}

const defaultFilters: Filters = {
  supplier: null,
  minStock: "",
  maxStock: "",
  status: "",
  soldInCurrentWeek: false,
};

export interface FiltersDto {
  supplierId?: number;
  minStock?: number;
  maxStock?: number;
  status?: ProductStatus;
  subtractDaysForUnsoldProducts?: number;
  supplier?: Supplier;
  soldInCurrentWeek?: boolean;
}

interface Props {
  open: boolean;
  onApplyFilters(dto: FiltersDto): void;
  onCancel: CallbackFunction;
}

export default function NecessaryGoodsPageFiltersDialog({
  open,
  onApplyFilters,
  onCancel,
}: Props) {
  const [searchSupplierTextInput, setSearchSupplierTextInput] = useState("");
  const [supplierOptions, setSupplierOptions] = useState<Supplier[]>([]);
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);

  const handleApplyFilters = () => {
    const dto: FiltersDto = {};
    if (filters.supplier) {
      dto.supplierId = filters.supplier.id;
      dto.supplier = filters.supplier;
    }
    if (filters.minStock) {
      dto.minStock = filters.minStock;
    }
    if (filters.maxStock) {
      dto.maxStock = filters.maxStock;
    }
    if (filters.status) {
      dto.status = filters.status;
    }
    if (filters.soldInCurrentWeek) {
      dto.soldInCurrentWeek = filters.soldInCurrentWeek;
    }
    setAppliedFilters(filters);
    onApplyFilters(dto);
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleOnClose = () => {
    onCancel();
    setFilters(appliedFilters);
  };

  // useEffect(() => {
  //   if (!searchSupplierTextInput) {
  //     supplierRequest
  //       .findAll(10, 0)
  //       .then((data) => setSupplierOptions(data.results));
  //     return;
  //   }
  //
  //   const timeoutId = setTimeout(() => {
  //     supplierRequest
  //       .findAll(-1, 0, searchSupplierTextInput)
  //       .then((data) => setSupplierOptions(data.results));
  //   }, 500);
  //
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [searchSupplierTextInput]);

  useEffect(() => {
    supplierRequest
      .findAll(-1, 0)
      .then((data) => setSupplierOptions(data.results));
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={handleOnClose}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>Filtrare produse</Grid>
          <Grid item>
            <IconButton onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              forcePopupIcon
              onKeyDown={(e) => e.stopPropagation()}
              fullWidth
              value={filters.supplier}
              onChange={(event, newValue) => {
                setFilters((prev) => ({
                  ...prev,
                  supplier: newValue ? (newValue as Supplier) : null,
                }));
              }}
              options={supplierOptions}
              // filterOptions={(options) => options}
              getOptionLabel={(option) => `${(option as Supplier).name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={({
                inputProps: { onChange, ...restInputProps },
                ...params
              }) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...restInputProps,
                    placeholder: "Selecție furnizor",
                    onChange: (...args) => {
                      setSearchSupplierTextInput(
                        // @ts-ignore
                        args[0].target.value,
                      );
                      // @ts-ignore
                      onChange(...args);
                    },
                  }}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="minStock"
              name="minStock"
              label="Stoc minim"
              type="number"
              inputProps={{ min: 0 }}
              value={filters.minStock}
              onChange={(e) => {
                const value = +e.target.value;
                setFilters((prev) => ({
                  ...prev,
                  minStock: !value || value <= 0 ? "" : value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="maxStock"
              name="maxStock"
              label="Stoc maxim"
              type="number"
              inputProps={{ min: 0 }}
              value={filters.maxStock}
              onChange={(e) => {
                const value = +e.target.value;
                setFilters((prev) => ({
                  ...prev,
                  maxStock: !value || value <= 0 ? "" : value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                placeholder="Status"
                labelId="status-label"
                id="status"
                value={filters.status}
                label="Status"
                endAdornment={
                  <IconButton
                    size="small"
                    sx={{ display: filters.status ? "" : "none", mr: 2 }}
                    onClick={() =>
                      setFilters((prev) => ({ ...prev, status: "" }))
                    }
                  >
                    <ClearIcon sx={{ width: 20 }} />
                  </IconButton>
                }
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    status: e.target.value as ProductStatus,
                  }))
                }
              >
                <MenuItem value={ProductStatus.Initial}>Inițial</MenuItem>
                <MenuItem value={ProductStatus.Pending}>În așteptare</MenuItem>
                <MenuItem value={ProductStatus.Accepted}>Aprobat</MenuItem>
                <MenuItem value={ProductStatus.Rejected}>Refuzat</MenuItem>
                <MenuItem value={ProductStatus.Ordered}>Comandat</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={filters.soldInCurrentWeek}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      soldInCurrentWeek: e.target.checked,
                    }))
                  }
                />
              }
              label="Produse vândute săptămâna curentă"
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={handleApplyFilters}
            >
              Aplică
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={handleResetFilters}
            >
              Resetează&nbsp;filtre
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
