import * as React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Button from "@mui/material/Button";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { Toast } from "../../../helpers/Toast";
import TextField from "@mui/material/TextField";

interface Props {
  toRejectId: number;
  onRejectSuccess: CallbackFunction;
  onResetToRejectId: CallbackFunction;
}

export default function ConfirmRejectDocumentDialog({
  toRejectId,
  onRejectSuccess,
  onResetToRejectId,
}: Props) {
  const openRejectConfirmation = Boolean(toRejectId);
  const [note, setNote] = useState("");
  const [noteTouched, setNoteTouched] = useState(false);

  const closeDialog = () => {
    setNoteTouched(false);
    setNote("");
    onResetToRejectId();
  };

  return (
    <Dialog open={openRejectConfirmation}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DoDisturbIcon sx={{ fontSize: "75px" }} />
        <DialogTitle id="alert-dialog-title">
          Sunteți sigur că doriți să refuzați acest document?
        </DialogTitle>
        <TextField
          placeholder="Menționați ce trebuie modificat..."
          error={noteTouched && !note}
          helperText={
            noteTouched && !note ? "Mențiunea e obligatorie" : undefined
          }
          value={note}
          onChange={(e) => setNote(e.target.value)}
          onBlur={() => setNoteTouched(true)}
          fullWidth
          sx={{ pl: 3, pr: 3, pb: 2 }}
        />
        <DialogActions
          sx={{
            minWidth: 520,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="error"
            onClick={() => {
              if (!note) {
                setNoteTouched(true);
                return;
              }
              supplierOrderRequest
                .reject(toRejectId, note)
                .then(() => {
                  onRejectSuccess();
                  Toast.showSuccess("Document refuzat cu succes.");
                })
                .catch((e) => {
                  console.error(e);
                  Toast.showError(
                    "A apărut o eroare. Vă rugăm încercați mai târziu.",
                  );
                })
                .finally(closeDialog);
            }}
            autoFocus
          >
            Refuză
          </Button>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={closeDialog}
          >
            Renunță
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
