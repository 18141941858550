import { ReactNode, useContext } from "react";
import { ToolbarContext } from "../contexts/ToolbarProvider";
import { Portal } from "@mui/material";

export default function ToolbarPortal({ children }: { children: ReactNode }) {
  const { toolbarPortalNode } = useContext(ToolbarContext);

  return toolbarPortalNode ? (
    <Portal container={toolbarPortalNode}>{children}</Portal>
  ) : null;
}
