import axios, {
  AxiosInterceptorManager,
  AxiosRequestConfig,
  Method,
} from "axios";
import eventEmitter, { EVENTS } from "../eventEmitter";
import { AUTH_ME_SUBROUTE, HttpStatusCode } from "./constants";

export interface QueryParam {
  key: string;
  value: string;
}

export interface Pagination {
  total: number;
  limit: string;
  offset: string;
}

abstract class AbstractRequest {
  get apiProtocol(): string {
    return import.meta.env.VITE_API_PROTOCOL;
  }

  get apiHost(): string {
    return import.meta.env.VITE_API_HOST;
  }

  get apiPort(): string {
    return import.meta.env.VITE_API_PORT;
  }

  get apiEntryRoute(): string {
    return "/api";
  }

  get apiUrl(): string {
    return `${this.apiBaseUrl}${this.apiEntryRoute}`;
  }

  get apiBaseUrl(): string {
    return `${this.apiProtocol}://${this.apiHost}${
      this.apiPort ? `:${this.apiPort}` : ""
    }`;
  }

  abstract get apiResource(): string;

  get apiRoute(): string {
    return `${this.apiUrl}/${this.apiResource}`;
  }

  abstract get routes(): Record<string, string>;

  async request(
    method: Method,
    url: string,
    data?: unknown,
    extraConfig?: AxiosRequestConfig,
  ) {
    let result;
    try {
      result = await axios({ method, url, data, ...extraConfig });
    } catch (e: any) {
      const responseUrl = e?.request?.responseURL;
      if (
        typeof responseUrl === "string" &&
        !responseUrl.endsWith(AUTH_ME_SUBROUTE) &&
        e?.response?.status === HttpStatusCode.UNAUTHORIZED
      ) {
        eventEmitter.emit(EVENTS.API.UNAUTHORIZED_ACCESS_REQUEST);
      }
      throw e;
    }
    return result;
  }

  getRequestInterceptors(): AxiosInterceptorManager<AxiosRequestConfig> {
    return axios.interceptors.request;
  }

  addRequestInterceptor(
    handler: (
      config: AxiosRequestConfig,
    ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>,
  ): number {
    return this.getRequestInterceptors().use(handler);
  }

  removeRequestInterceptor(id: number): void {
    this.getRequestInterceptors().eject(id);
  }

  buildQueryParams(queryParams: QueryParam[]): string {
    let queryString = "";
    queryParams.forEach((queryParam) => {
      if (queryString) {
        queryString += `&${queryParam.key}=${queryParam.value}`;
      } else {
        queryString = `?${queryParam.key}=${queryParam.value}`;
      }
    });

    return queryString;
  }
}

export default AbstractRequest;
