import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

export const EVENTS = {
  API: {
    UNAUTHORIZED_ACCESS_REQUEST: "api/unauthorizedAccessRequest",
  },
  REJECTED_DOCUMENTS: {
    REFETCH_PENDING_DOCUMENTS: "rejectedDocuments/refetchPendingDocuments",
  },
  ACCEPTED_DOCUMENTS: {
    REFETCH_ORDERED_DOCUMENTS: "rejectedDocuments/refetchOrderedDocuments",
  },
};

export default eventEmitter;
