export enum UserRole {
  Admin = "admin",
  Manager = "manager",
}

export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export interface CreateUserRequestBody {
  username: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export interface UpdateUserRequestBody extends CreateUserRequestBody {
  password?: string;
}
