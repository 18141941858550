import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authRequest, LoginData } from "../../api/AuthRequest";

export const login = createAsyncThunk("auth/login", (loginData: LoginData) =>
  authRequest.login(loginData),
);

export const logout = createAsyncThunk("auth/logout", () =>
  authRequest.logout(),
);

export const me = createAsyncThunk("auth/me", () => authRequest.me());

export interface AuthState {
  user: {
    id: string | null;
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    role: string | null;
  };
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    role: null,
  },
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
    builder.addCase(me.fulfilled, (state: AuthState, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    });
    builder.addCase(me.rejected, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
  },
});

export default authSlice.reducer;
