import * as React from "react";
import Typography from "@mui/material/Typography";
import Layout from "../../layout/Layout";
import { Alert, AlertTitle, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import OrderedDocumentsDataGrid from "../../components/documents/data-grids/OrderedDocumentsDataGrid";
import { useEffect, useState } from "react";
import { supplierOrderRequest } from "../../api/SupplierOrderRequest";

const StatsCard = ({ count, title }: { count: number; title: string }) => (
  <Box
    sx={{
      boxShadow: 3,
      borderRadius: 2,
      p: 2,
    }}
  >
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {title}
    </Typography>
    <Typography component="p" variant="h4">
      {count}
    </Typography>
  </Box>
);

export default function DashboardPage() {
  const [stats, setStats] = useState({
    pendingCount: 0,
    acceptedCount: 0,
    lastWeekAcceptedCount: 0,
    belowMinStockProductsCount: 0,
    unsoldProductsInLast30DaysCount: 0,
    unsoldProductsInLast45DaysCount: 0,
  });
  useEffect(() => {
    supplierOrderRequest.getStats().then((data) =>
      setStats({
        pendingCount: data.pendingSupplyOrdersCount,
        acceptedCount: data.acceptedSupplyOrdersCount,
        belowMinStockProductsCount: data.belowMinStockProductsCount,
        lastWeekAcceptedCount: data.lastWeekAcceptedSupplyOrdersCount,
        unsoldProductsInLast30DaysCount: data.unsoldProductsInLast30DaysCount,
        unsoldProductsInLast45DaysCount: data.unsoldProductsInLast45DaysCount,
      }),
    );
  }, []);
  const showWarning = Boolean(
    stats.acceptedCount &&
      new Date().getHours() >= 18 &&
      new Date().getHours() <= 23,
  );

  return (
    <Layout title="Dashboard">
      <Grid item container spacing={3} mb={3}>
        <Grid item xs>
          <StatsCard
            title="Documente aprobate in ultimele 7 zile"
            count={stats.lastWeekAcceptedCount}
          />
        </Grid>
        <Grid item xs>
          <StatsCard
            title="Documente ce asteapta aprobare"
            count={stats.pendingCount}
          />
        </Grid>
        <Grid item xs>
          <StatsCard
            title="Produse cu stoc sub minim (7 buc)"
            count={stats.belowMinStockProductsCount}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs>
          <StatsCard
            title="Produse care nu s-au vândut deloc în ultimele 30 de zile"
            count={stats.unsoldProductsInLast30DaysCount}
          />
        </Grid>
        <Grid item xs>
          <StatsCard
            title="Produse care nu s-au vândut deloc în ultimele 45 de zile"
            count={stats.unsoldProductsInLast45DaysCount}
          />
        </Grid>
      </Grid>
      {showWarning && (
        <Grid item sx={{ mt: 3 }}>
          <Alert severity="warning">
            <AlertTitle>Atenție</AlertTitle>
            Nu uita să comanzi produsele de la furnizor!
          </Alert>
        </Grid>
      )}
      <Grid item container>
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          sx={{ mt: showWarning ? 3 : 8, mb: 1 }}
        >
          Documente comandate
        </Typography>
        <Box sx={{ height: 400, width: "100%" }}>
          <OrderedDocumentsDataGrid />
        </Box>
      </Grid>
    </Layout>
  );
}
