import { useEffect, useState } from "react";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { SupplierOrderStatus } from "../../../api/types/supplier-order";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {
  convertApiOrderToPdfOrder,
  downloadSupplierOrderPdf,
  printSupplierOrderPdf,
  SupplyOrderPdf,
} from "../../../helpers/pdf-renderer";
import ViewDocumentDialog from "../dialogs/ViewDocumentDialog";
import ConfirmDeleteDocumentDialog from "../dialogs/ConfirmDeleteDocumentDialog";
import PrintIcon from "@mui/icons-material/Print";
import { useAppSelector } from "../../../hooks/redux";
import { isAdminSelector } from "../../../redux/slices/selectors";
import { Toast } from "../../../helpers/Toast";
import EditSupplierOrderDialog, {
  EditSupplierOrder,
} from "../dialogs/EditSupplierOrderDialog";
import QuickSearchToolbar from "../../common/QuickSearchToolbar";
import eventEmitter, { EVENTS } from "../../../eventEmitter";

interface RejectedRow {
  id: number;
  supplierName: string;
  products: string;
  generationDate: Date;
  rejectedDate: Date;
  comment: string;
  supplyOrderPdf: SupplyOrderPdf;
}

export default function RejectedDocumentsDataGrid() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<RejectedRow[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [supplyOrderPdf, setSupplyOrderPdf] = useState<SupplyOrderPdf | null>(
    null,
  );
  const [toDeleteId, setToDeleteId] = useState(0);
  const isAdmin = useAppSelector(isAdminSelector);
  const [editSupplierOrder, setEditSupplierOrder] =
    useState<EditSupplierOrder>(null);

  const [searchText, setSearchText] = useState("");

  const fetchData = () => {
    setPageIsLoading(true);
    supplierOrderRequest
      .findAll(
        pageSize,
        page * pageSize,
        SupplierOrderStatus.Rejected,
        searchText,
      )
      .then((data) => {
        setRows(
          data.results.map((order) => ({
            id: order.id,
            supplierName: order.supplier.name,
            products: order.rows.map((row) => row.productId).join(", "),
            generationDate: new Date(order.createdAt),
            rejectedDate: new Date(order.lastStatusDate!),
            comment: order.lastStatusNote || "",
            supplyOrderPdf: convertApiOrderToPdfOrder(order),
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError(
          "A apărut o eroare la încărcarea documentelor refuzate!",
        );
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchText]);

  const columns: GridColDef<RejectedRow>[] = [
    {
      field: "id",
      headerName: "Cod fișă",
      width: 75,
      sortable: false,
    },
    {
      field: "supplierName",
      headerName: "Furnizor",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "products",
      headerName: "Produse",
      width: 150,
      flex: 2,
      sortable: false,
    },
    {
      field: "generationDate",
      headerName: "Dată generare",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "rejectedDate",
      headerName: "Dată refuz",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "comment",
      headerName: "Comentariu",
      // width: 150,
      flex: 4,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Acțiune",
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              onClick={() => setSupplyOrderPdf(row.supplyOrderPdf)}
            >
              <VisibilityIcon />
            </IconButton>
            {!isAdmin && (
              <IconButton
                size="small"
                color="success"
                onClick={() => setEditSupplierOrder(row.supplyOrderPdf)}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton
              size="small"
              color="info"
              onClick={() => downloadSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <DownloadIcon />
            </IconButton>
            {!isAdmin && (
              <IconButton
                size="small"
                color="error"
                onClick={() => setToDeleteId(row.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {isAdmin && (
              <IconButton
                size="small"
                color="success"
                onClick={() => printSupplierOrderPdf(row.supplyOrderPdf)}
              >
                <PrintIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-cell:focus-within": {
            outline: "none ",
          },
        }}
        localeText={{
          noRowsLabel: "Nu există documente refuzate",
          toolbarQuickFilterPlaceholder: "Căutare...",
        }}
        slotProps={{ pagination: { labelRowsPerPage: "Rânduri pe pagină:" } }}
        disableColumnMenu
        rowCount={rowCount}
        rows={rows}
        columns={columns}
        paginationMode="server"
        pagination
        pageSizeOptions={[5, 25, 50]}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        loading={pageIsLoading}
        disableRowSelectionOnClick
        slots={{ toolbar: QuickSearchToolbar }}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => {
          setSearchText(filterModel.quickFilterValues?.join(" ") || "");
        }}
      />
      <ViewDocumentDialog
        supplyOrder={supplyOrderPdf}
        onResetSupplyOrder={() => setSupplyOrderPdf(null)}
      />
      <ConfirmDeleteDocumentDialog
        toDeleteId={toDeleteId}
        onDeleteSuccess={fetchData}
        onResetToDeleteId={() => setToDeleteId(0)}
      />
      {!isAdmin && (
        <EditSupplierOrderDialog
          supplierOrder={editSupplierOrder}
          onClose={() => setEditSupplierOrder(null)}
          onSuccess={() => {
            fetchData();
            eventEmitter.emit(
              EVENTS.REJECTED_DOCUMENTS.REFETCH_PENDING_DOCUMENTS,
            );
          }}
        />
      )}
    </>
  );
}
