import Layout from "../../layout/Layout";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import PendingDocumentsDataGrid from "../../components/documents/data-grids/PendingDocumentsDataGrid";

export default function PendingDocumentsPage() {
  return (
    <Layout title="Documente">
      <Grid>
        <Typography component="h2" variant="h4" color="primary">
          Documente de aprobat
        </Typography>
        <Box sx={{ height: 500, width: "100%" }}>
          <PendingDocumentsDataGrid />
        </Box>
      </Grid>
    </Layout>
  );
}
