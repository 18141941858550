import AbstractRequest from "./AbstractRequest";
import { ApiMethod } from "./constants";
import {
  EditSupplierOrderRequestBody,
  GenerateSupplierOrderRequestBody,
  SupplierOrder,
  SupplierOrderStatus,
} from "./types/supplier-order";

export class SupplierOrderRequest extends AbstractRequest {
  get apiResource(): string {
    return "supplier-order";
  }

  get dynamicIds() {
    return {
      id: "{id}",
    };
  }

  get routes(): Record<string, string> {
    return {
      FIND_ALL: `${this.apiRoute}`,
      CREATE: `${this.apiRoute}`,
      UPDATE: `${this.apiRoute}/`,
      DELETE: `${this.apiRoute}/`,
      ACCEPT: `${this.apiRoute}/{id}/accept`,
      REJECT: `${this.apiRoute}/{id}/reject`,
      ORDER: `${this.apiRoute}/{id}/order`,
      GET_STATS: `${this.apiRoute}/stats`,
    };
  }

  async findAll(
    limit: number,
    offset: number,
    status: SupplierOrderStatus,
    searchText?: string,
  ): Promise<{ results: SupplierOrder[]; total: number }> {
    const queryParams = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
      { key: "status", value: status },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    const result = await this.request(
      ApiMethod.GET,
      this.routes.FIND_ALL + this.buildQueryParams(queryParams),
    );
    return result.data;
  }

  async create(dto: GenerateSupplierOrderRequestBody): Promise<unknown> {
    const result = await this.request(ApiMethod.POST, this.routes.CREATE, dto);

    return result.data;
  }

  async update(
    id: number,
    dto: EditSupplierOrderRequestBody,
  ): Promise<unknown> {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE + id,
      dto,
    );

    return result.data;
  }

  async delete(id: number): Promise<unknown> {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE + id,
    );

    return result.data;
  }

  async accept(id: number, note?: string): Promise<unknown> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ACCEPT.replace(this.dynamicIds.id, id.toString()),
      note ? { note } : undefined,
    );

    return result.data;
  }

  async reject(id: number, note?: string): Promise<unknown> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REJECT.replace(this.dynamicIds.id, id.toString()),
      note ? { note } : undefined,
    );

    return result.data;
  }

  async order(id: number, fileId: string, note?: string): Promise<unknown> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ORDER.replace(this.dynamicIds.id, id.toString()),
      { fileId, ...(note ? { note } : {}) },
    );

    return result.data;
  }

  async getStats(): Promise<{
    pendingSupplyOrdersCount: number;
    acceptedSupplyOrdersCount: number;
    lastWeekAcceptedSupplyOrdersCount: number;
    belowMinStockProductsCount: number;
    unsoldProductsInLast30DaysCount: number;
    unsoldProductsInLast45DaysCount: number;
  }> {
    const result = await this.request(ApiMethod.GET, this.routes.GET_STATS);

    return result.data;
  }
}

export const supplierOrderRequest = new SupplierOrderRequest();
