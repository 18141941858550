import { Dialog } from "@mui/material";
import {
  getSupplierOrderPdf,
  SupplyOrderPdf,
} from "../../../helpers/pdf-renderer";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

interface Props {
  supplyOrder: SupplyOrderPdf | null;
  onResetSupplyOrder: CallbackFunction;
}

const StyledIconButton = styled(IconButton)`
  position: fixed;
  top: 24px;
  right: 24px;
  color: white;

  svg {
    width: 56px;
    height: 56px;
  }
`;

export default function ViewDocumentDialog({
  supplyOrder,
  onResetSupplyOrder,
}: Props) {
  const openDocumentDialog = Boolean(supplyOrder);

  return (
    <Dialog
      maxWidth={false}
      open={openDocumentDialog}
      onClose={onResetSupplyOrder}
    >
      <StyledIconButton onClick={onResetSupplyOrder}>
        <CloseIcon />
      </StyledIconButton>
      {supplyOrder && getSupplierOrderPdf(supplyOrder, { withPdfViewer: true })}
    </Dialog>
  );
}
