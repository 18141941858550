import { ProductStatus } from "../api/types/product";

export const getProductStatusLocale = (status: ProductStatus | string) => {
  const localeText = {
    [ProductStatus.Initial]: "Inițial",
    [ProductStatus.Pending]: "În așteptare",
    [ProductStatus.Accepted]: "Aprobat",
    [ProductStatus.Rejected]: "Refuzat",
    [ProductStatus.Ordered]: "Comandat",
  };

  return localeText[status as ProductStatus] || status;
};
