import AbstractRequest from "./AbstractRequest";
import { ApiMethod } from "./constants";
import { Product } from "./types/product";
import { FiltersDto } from "../components/documents/dialogs/NecessaryGoodsPageFiltersDialog";
import { ExportProductsToPdfResponseBody } from "./types/file";

export class ProductRequest extends AbstractRequest {
  get apiResource(): string {
    return "product";
  }

  get routes(): Record<string, string> {
    return {
      FIND_ALL: `${this.apiRoute}`,
      EXPORT_TO_PDF: `${this.apiRoute}/export-to-pdf`,
      GET_AVG_ORDERED_QUANTITY: `${this.apiRoute}/avg-ordered-quantity/`,
      FORCE_IMPORT: `${this.apiRoute}/force-import`,
    };
  }

  async findAll(
    limit: number,
    offset: number,
    searchText?: string,
    filtersDto?: FiltersDto,
    sortDto?: { sortBy: string; sortByDirection: "ASC" | "DESC" },
  ): Promise<{ results: Product[]; total: number }> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.FIND_ALL +
        this.buildQueryParams(
          this.buildFindAllQueryParams(
            limit,
            offset,
            searchText,
            filtersDto,
            sortDto,
          ),
        ),
    );
    return result.data;
  }

  async exportToPdf(
    limit: number,
    offset: number,
    searchText?: string,
    filtersDto?: FiltersDto,
    sortDto?: { sortBy: string; sortByDirection: "ASC" | "DESC" },
  ): Promise<ExportProductsToPdfResponseBody> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.EXPORT_TO_PDF +
        this.buildQueryParams(
          this.buildFindAllQueryParams(
            limit,
            offset,
            searchText,
            filtersDto,
            sortDto,
          ),
        ),
    );
    return result.data;
  }

  async getAvgOrderedQuantity(code: string) {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_AVG_ORDERED_QUANTITY + code,
    );

    return result.data;
  }

  async forceImport(): Promise<string> {
    const now = new Date();

    const result = await this.request(
      ApiMethod.POST,
      this.routes.FORCE_IMPORT,
      {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      },
    );

    return result.data;
  }

  private buildFindAllQueryParams(
    limit: number,
    offset: number,
    searchText?: string,
    filtersDto?: FiltersDto,
    sortDto?: { sortBy: string; sortByDirection: "ASC" | "DESC" },
  ) {
    const queryParams = [
      { key: "limit", value: `${limit}` },
      { key: "offset", value: `${offset}` },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    if (filtersDto?.supplierId) {
      queryParams.push({
        key: "supplierId",
        value: filtersDto.supplierId.toString(),
      });
    }

    if (filtersDto?.minStock) {
      queryParams.push({
        key: "minStock",
        value: filtersDto.minStock.toString(),
      });
    }

    if (filtersDto?.maxStock) {
      queryParams.push({
        key: "maxStock",
        value: filtersDto.maxStock.toString(),
      });
    }

    if (filtersDto?.status) {
      queryParams.push({ key: "status", value: filtersDto.status });
    }

    if (filtersDto?.subtractDaysForUnsoldProducts) {
      queryParams.push({
        key: "subtractDaysForUnsoldProducts",
        value: filtersDto.subtractDaysForUnsoldProducts.toString(),
      });
    }
    if (filtersDto?.soldInCurrentWeek) {
      queryParams.push({
        key: "soldInCurrentWeek",
        value: filtersDto.soldInCurrentWeek.toString(),
      });
    }

    if (sortDto) {
      queryParams.push({
        key: "sortBy",
        value: sortDto.sortBy,
      });
      queryParams.push({
        key: "sortByDirection",
        value: sortDto.sortByDirection,
      });
    }

    return queryParams;
  }
}

export const productRequest = new ProductRequest();
