export const PATH = {
  DASHBOARD: "/",
  LOGIN: "/login",
  UNSOLD_GOODS: "/unsold-goods",
  NECESSARY_GOODS: "/necessary-goods",
  GENERATE_SUPPLY_ORDERS: "/generate-supply-orders",
  DOCUMENTS: "/documents",
  PENDING_DOCUMENTS: "/pending-documents",
  USERS: "/users",
};
