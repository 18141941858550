import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { Product } from "../../../api/types/product";
import { Autocomplete, CircularProgress, Grid } from "@mui/material";
import { productRequest } from "../../../api/ProductRequest";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { Toast } from "../../../helpers/Toast";

const validationSchema = Yup.object().shape({
  supplierOrderRows: Yup.array().of(
    Yup.object().shape({
      product: Yup.object().shape({
        code: Yup.string().required("Produsul e obligatoriu"),
        name: Yup.string(),
      }),
      quantity: Yup.number()
        .min(0.1, "Cantitatea trebuie să fie minim 0.1")
        .required("Cantitatea e obligatorie"),
    }),
  ),
});

interface Props {
  onCancel: CallbackFunction;
  onSuccess: CallbackFunction;
  supplierOrderId: number;
  supplierId: number;
  initialValues: {
    id: number;
    product: { code: string; name: string; supplierId: number };
    quantity: number | string;
  }[];
}

type DiffProductSupplierErrorMap = Record<string, boolean>;

const EditSupplierOrderForm = ({
  onCancel,
  onSuccess,
  supplierOrderId,
  supplierId,
  initialValues = [
    {
      id: Math.random(),
      product: { code: "", name: "", supplierId: 0 },
      quantity: "",
    },
  ],
}: Props) => {
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [searchProductTextInput, setSearchProductTextInput] = useState("");
  const [productOptions, setProductOptions] = useState<Product[]>([]);
  /*
   * This object is always reset if using the form inside dialog component with keepMounted prop false
   * We don't need to reset it imperatively for now
   */
  const [diffProductSupplierErrorMap, setDiffProductSupplierErrorMap] =
    useState<DiffProductSupplierErrorMap>({});

  useEffect(() => {
    setDiffProductSupplierErrorMap((prev) => ({
      ...prev,
      ...initialValues.reduce<DiffProductSupplierErrorMap>((acc, value) => {
        acc[value.product.code] = value.product.supplierId !== supplierId;
        return acc;
      }, {}),
    }));
  }, [initialValues]);

  useEffect(() => {
    if (!searchProductTextInput) {
      productRequest
        .findAll(10, 0)
        .then((data) => setProductOptions(data.results));
      return;
    }

    const timeoutId = setTimeout(() => {
      productRequest
        .findAll(-1, 0, searchProductTextInput)
        .then((data) => setProductOptions(data.results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchProductTextInput]);

  return (
    <Formik
      initialValues={{
        supplierOrderRows: initialValues,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setRequestIsLoading(true);
        supplierOrderRequest
          .update(supplierOrderId, {
            rows: values.supplierOrderRows.map((row) => ({
              productCode: row.product.code,
              quantity: +row.quantity,
            })),
          })
          .then(() => {
            Toast.showSuccess("Document modificat cu succes.");
            onSuccess();
          })
          .catch((e) => {
            console.error(e);
            Toast.showError(
              e.response?.data?.code === 1000
                ? "Nu puteți avea 2 sau mai multe rânduri cu același cod de produs!"
                : "A apărut o eroare. Vă rugăm încercați mai târziu.",
            );
          })
          .finally(() => {
            setRequestIsLoading(false);
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form noValidate autoComplete="off">
          <FieldArray name="supplierOrderRows">
            {({ push, remove }) => (
              <Box>
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    push({
                      id: Math.random(),
                      product: { code: "", name: "", supplierId: 0 },
                      quantity: "",
                    })
                  }
                  sx={{ mb: 2 }}
                >
                  Adaugă un rănd nou
                </Button>
                {values.supplierOrderRows.map((row, index) => {
                  const product = `supplierOrderRows[${index}].product`;
                  const touchedProduct = getIn(touched, product);
                  const errorProduct = getIn(errors, product)?.code;

                  const quantity = `supplierOrderRows[${index}].quantity`;
                  const touchedQuantity = getIn(touched, quantity);
                  const errorQuantity = getIn(errors, quantity);

                  return (
                    <Box
                      key={row.id}
                      sx={{ display: "flex", alignItems: "start", mb: 2 }}
                    >
                      <Autocomplete
                        sx={{ flex: 1, mr: 1, minWidth: 300 }}
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        handleHomeEndKeys
                        forcePopupIcon
                        value={row.product as Product}
                        onChange={(event, newValue) => {
                          if ((newValue as Product).supplierId !== supplierId) {
                            setDiffProductSupplierErrorMap((prev) => ({
                              ...prev,
                              [(newValue as Product).code]: true,
                            }));
                          }
                          setFieldValue(product, newValue);
                        }}
                        options={productOptions}
                        filterOptions={(options) => options}
                        disableClearable
                        getOptionLabel={(option) =>
                          `${(option as Product).name}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.code === value.code
                        }
                        noOptionsText="Fără opțiuni"
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.code}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={({
                          inputProps: { onChange, ...restInputProps },
                          ...params
                        }) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...restInputProps,
                              placeholder: "Alege produs",
                              onChange: (...args) => {
                                setSearchProductTextInput(
                                  // @ts-ignore
                                  args[0].target.value,
                                );
                                // @ts-ignore
                                onChange(...args);
                              },
                            }}
                            label="Produs"
                            size="small"
                            required
                            helperText={
                              touchedProduct && errorProduct
                                ? errorProduct
                                : diffProductSupplierErrorMap[row.product.code]
                                ? "Furnizor standard produs diferă"
                                : ""
                            }
                            error={Boolean(touchedProduct && errorProduct)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      />
                      <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        variant="outlined"
                        label="Cantitate"
                        type="number"
                        name={quantity}
                        value={row.quantity}
                        required
                        helperText={
                          touchedQuantity && errorQuantity ? errorQuantity : ""
                        }
                        error={Boolean(touchedQuantity && errorQuantity)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <IconButton
                        type="button"
                        color="error"
                        onClick={() => remove(index)}
                        disabled={values.supplierOrderRows.length < 2}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            )}
          </FieldArray>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Grid container gap={1}>
            <Grid item flex={1}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={requestIsLoading}
              >
                Salvează
                {requestIsLoading && (
                  <CircularProgress sx={{ ml: 1 }} size={16} />
                )}
              </Button>
            </Grid>
            <Grid item flex={1}>
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={onCancel}
                disabled={requestIsLoading}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditSupplierOrderForm;
