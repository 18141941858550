import { toast, ToastPosition, Theme } from "react-toastify";

const defaultSettings = {
  position: "top-right" as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored" as Theme,
};

export class Toast {
  static showSuccess(message: string) {
    toast.success(message, defaultSettings);
  }
  static showError(message: string) {
    toast.error(message, defaultSettings);
  }
}
