import * as React from "react";
import { useEffect, useState } from "react";
import { supplierOrderRequest } from "../../../api/SupplierOrderRequest";
import { SupplierOrderStatus } from "../../../api/types/supplier-order";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {
  convertApiOrderToPdfOrder,
  downloadSupplierOrderPdf,
  SupplyOrderPdf,
} from "../../../helpers/pdf-renderer";
import ConfirmDeleteDocumentDialog from "../dialogs/ConfirmDeleteDocumentDialog";
import ViewDocumentDialog from "../dialogs/ViewDocumentDialog";
import { useAppSelector } from "../../../hooks/redux";
import { isAdminSelector } from "../../../redux/slices/selectors";
import ConfirmAcceptDocumentDialog from "../dialogs/ConfirmAcceptDocumentDialog";
import ConfirmRejectDocumentDialog from "../dialogs/ConfirmRejectDocumentDialog";
import { Toast } from "../../../helpers/Toast";
import EditSupplierOrderDialog, {
  EditSupplierOrder,
} from "../dialogs/EditSupplierOrderDialog";
import QuickSearchToolbar from "../../common/QuickSearchToolbar";
import eventEmitter, { EVENTS } from "../../../eventEmitter";

interface PendingRow {
  id: number;
  supplierName: string;
  products: string;
  generationDate: Date;
  supplyOrderPdf: SupplyOrderPdf;
}

export default function PendingDocumentsDataGrid() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [rows, setRows] = useState<PendingRow[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const [supplyOrderPdf, setSupplyOrderPdf] = useState<SupplyOrderPdf | null>(
    null,
  );
  const [toDeleteId, setToDeleteId] = useState(0);
  const [toAcceptId, setToAcceptId] = useState(0);
  const [toRejectId, setToRejectId] = useState(0);
  const isAdmin = useAppSelector(isAdminSelector);
  const [editSupplierOrder, setEditSupplierOrder] =
    useState<EditSupplierOrder>(null);

  const [searchText, setSearchText] = useState("");

  const fetchData = () => {
    setPageIsLoading(true);
    supplierOrderRequest
      .findAll(
        pageSize,
        page * pageSize,
        SupplierOrderStatus.Pending,
        searchText,
      )
      .then((data) => {
        setRows(
          data.results.map((order) => ({
            id: order.id,
            supplierName: order.supplier.name,
            products: order.rows.map((row) => row.productId).join(", "),
            generationDate: new Date(order.createdAt),
            supplyOrderPdf: convertApiOrderToPdfOrder(order),
          })),
        );
        setRowCount(data.total);
      })
      .catch((e) => {
        console.error(e);
        Toast.showError(
          "A apărut o eroare la încărcarea documentelor în așteptare!",
        );
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchText]);

  useEffect(() => {
    eventEmitter.on(
      EVENTS.REJECTED_DOCUMENTS.REFETCH_PENDING_DOCUMENTS,
      fetchData,
    );

    return () => {
      eventEmitter.off(
        EVENTS.REJECTED_DOCUMENTS.REFETCH_PENDING_DOCUMENTS,
        fetchData,
      );
    };
  }, []);

  const columns: GridColDef<PendingRow>[] = [
    {
      field: "id",
      headerName: "Cod fișă",
      width: 75,
      sortable: false,
    },
    {
      field: "supplierName",
      headerName: "Furnizor",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "products",
      headerName: "Produse",
      width: 150,
      flex: 2,
      sortable: false,
    },
    {
      field: "generationDate",
      headerName: "Dată generare",
      width: 160,
      sortable: false,
      valueGetter(params) {
        return params.value.toLocaleString();
      },
    },
    {
      field: "action",
      headerName: "Acțiune",
      minWidth: isAdmin ? 180 : 150,
      maxWidth: isAdmin ? 180 : 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              size="small"
              onClick={() => setSupplyOrderPdf(row.supplyOrderPdf)}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              size="small"
              color="success"
              onClick={() =>
                setEditSupplierOrder({
                  ...row.supplyOrderPdf,
                  createdAt: row.generationDate,
                })
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              color="info"
              onClick={() => downloadSupplierOrderPdf(row.supplyOrderPdf)}
            >
              <DownloadIcon />
            </IconButton>
            {!isAdmin && (
              <IconButton
                size="small"
                color="error"
                onClick={() => setToDeleteId(row.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {isAdmin && (
              <>
                <IconButton
                  size="small"
                  color="success"
                  onClick={() => setToAcceptId(row.id)}
                >
                  <DoneOutlineIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => setToRejectId(row.id)}
                >
                  <DoDisturbIcon />
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-cell:focus-within": {
            outline: "none ",
          },
        }}
        localeText={{
          noRowsLabel: isAdmin
            ? "Nu există documente de aprobat"
            : "Nu există documente în așteptare",
          toolbarQuickFilterPlaceholder: "Căutare...",
        }}
        slotProps={{ pagination: { labelRowsPerPage: "Rânduri pe pagină:" } }}
        disableColumnMenu
        rowCount={rowCount}
        rows={rows}
        columns={columns}
        paginationMode="server"
        pagination
        pageSizeOptions={[5, 25, 50]}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        loading={pageIsLoading}
        disableRowSelectionOnClick
        slots={{ toolbar: QuickSearchToolbar }}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => {
          setSearchText(filterModel.quickFilterValues?.join(" ") || "");
        }}
      />
      <ViewDocumentDialog
        supplyOrder={supplyOrderPdf}
        onResetSupplyOrder={() => setSupplyOrderPdf(null)}
      />
      <ConfirmDeleteDocumentDialog
        toDeleteId={toDeleteId}
        onDeleteSuccess={fetchData}
        onResetToDeleteId={() => setToDeleteId(0)}
      />
      <EditSupplierOrderDialog
        supplierOrder={editSupplierOrder}
        onClose={() => setEditSupplierOrder(null)}
        onSuccess={fetchData}
      />
      {isAdmin && (
        <>
          <ConfirmAcceptDocumentDialog
            toAcceptId={toAcceptId}
            onAcceptSuccess={fetchData}
            onResetToAcceptId={() => setToAcceptId(0)}
          />
          <ConfirmRejectDocumentDialog
            toRejectId={toRejectId}
            onRejectSuccess={fetchData}
            onResetToRejectId={() => setToRejectId(0)}
          />
        </>
      )}
    </>
  );
}
